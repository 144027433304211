import React, { useContext } from 'react'
import { AuthContext } from '../../app'
import { map as lMap, sortBy as lSort } from 'lodash'
import { Trans, withTranslation, useTranslation } from 'react-i18next'
import ListTableRow from '../molecules/ListTableRow'

const ListTable = (props) => {
  const { t } = useTranslation()
  const { theadI18nKeyList, tbodyList, sort, canEdit, canDelete, openDeleteModal, openEditModal } = props
  const filteredList = tbodyList.filter(item => item['deleted_at'] === null)

  const { userDetails } = useContext(AuthContext)
  const userId = userDetails['id']

  return (
    <table className='table' cellSpacing={0}>
      <thead>
        <tr>
          {theadI18nKeyList.map((i18nKey) => {
            return (
              <th key={i18nKey}>
                <Trans t={t} i18nKey={i18nKey} />
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {lMap(lSort(filteredList, sort), (columnObject) => (
          <ListTableRow
            key={columnObject['id']}
            columnObject={columnObject}
            canEdit={canEdit || userId === columnObject['id']}
            canDelete={canDelete}
            openEditModal={openEditModal}
            openDeleteModal={openDeleteModal}
          />
        ))}
      </tbody>
    </table>
  )
}

export default withTranslation()(ListTable)
