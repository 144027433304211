import React from 'react'

import Button from '../atoms/Button'
import ModalTitleLabel from '../atoms/ModalTitleLabel'

const DeleteConfirmModal = (props) => {
  const {
    closeDeleteModal,
    deleteData
  } = props

  return (
    <div className="modal-wrapper" onClick={closeDeleteModal} >
      <div className="modal-contents" onClick={e => { e.stopPropagation() }}>
        <ModalTitleLabel i18nKey='delete_message' />
        <div className="flex align-center mt-4 mb-4">
          <Button
            color='primary'
            i18nKey="execute"
            clickHandler={deleteData}
          />
          <Button
            color='default'
            i18nKey="cancel"
            clickHandler={closeDeleteModal}
          />
        </div>
      </div>
    </div>
  )
}

export default DeleteConfirmModal
