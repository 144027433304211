import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const Button = (props) => {
  const { t } = useTranslation()
  const {
    color='',
    className='',
    margin='',
    i18nKey,
    clickHandler,
    disabled=false
  } = props
  const generatedClassName =
    'btn ' + (disabled ? 'disabled ' : '' ) + color + className

  return (
    <button
      className={generatedClassName}
      onClick={clickHandler}
      disabled={disabled}
      style={{
        marginLeft: margin,
        marginRight: margin
      }}
    >
      <Trans t={t} i18nKey={i18nKey} />
    </button>
  )
}

export default Button
