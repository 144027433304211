import React, { useState } from 'react';
import { get as lGet } from 'lodash';
import Cookies from 'js-cookie';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import LoginHoc from './login_hoc';
import { apiLoginUser } from '../../utils/api';
import LanguageSwitcher from '../../components/molecules/LanguageSwitcher';
import { Trans, withTranslation } from 'react-i18next';
import ErrorMessage from '../../components/atoms/ErrorMessage'

const LoginPage = ({ t }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [token, setToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('')

  const verifyRecaptchaCallback = React.useCallback((token) => {
    setToken(token);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const redirectPath =  '/';
try {
      const {
        data: { data },
      } = await apiLoginUser(email, password, token);
      Cookies.set('token', data);
      window.location.href = redirectPath;
    } catch (e) {
      setErrorMessage('login_error');

      if (lGet(e, 'response.data.message') === 'RECAPTCHA_ERROR') {
        setError('Error occurred validating captcha. Please reload and try again');
      } else if (lGet(e, 'response.data.message') === 'DELETED') {
        setError('The user account is deactivated. Please contact support');
      }
      setRefreshReCaptcha(!refreshReCaptcha);
      // console.log(e);
    }
  };

  return (
    <>
      <LoginHoc>
        <h3 id="login-heading">
          <Trans t={t} i18nKey="loginAccount" />
        </h3>
        <form onSubmit={onSubmit} className="login-form">
          <div className="input-wrapper">
            <label className="input-label">
              <Trans t={t} i18nKey="email" />
            </label>
            <input
              required
              className="input fill"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="login email"
            />
          </div>
          <div className="input-wrapper">
            <label className="input-label">
              <Trans t={t} i18nKey="password" />
            </label>
            <input
              required
              className="input fill"
              type="password"
              value={password}
              data-id="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="login password"
            />
          </div>
          <div className='flex'>
            <div className='input-wrapper mr-4'>
              <ErrorMessage i18nKey={errorMessage} />
            </div>
          </div>
          <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
          {error && <div className="login-error">{error}</div>}
          <button className="login-submit" type="submit">
            <Trans t={t} i18nKey="login" />
          </button>


          <div className="login-lang-wrapper">
            <LanguageSwitcher small />
          </div>
        </form>
      </LoginHoc>
    </>
  );
};

export default withTranslation()(LoginPage);
