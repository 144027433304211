import React from "react";

const Floor = ({
 width, depth
}) => {
  // console.log(width, depth);
  return (
    <mesh position={[width / 2, 0, depth / 2]} rotation={[-Math.PI / 2, 0, 0]}>
      <planeGeometry args={[width, depth]} />
      <meshBasicMaterial color={"#d4d4d4"} />
    </mesh>
  );
}

export default Floor;
