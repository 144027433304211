import React from 'react'

import ServiceMenuTemplate from '../../../../templates/ServiceMenuTemplate'

const PickingMenu = () => {
  const props = {
    pageTitle: 'opt_picking_list',
    upperNavLinkTitle: 'newexec',
    upperNavTo: '/picking/newexec',
    lowerNavLinkTitle: 'reshis',
    lowerNavTo: '/picking/reshis',
    backTo: '/asoko2menu'
  }

  return <ServiceMenuTemplate props={props} />
}

export default PickingMenu
