import React from "react";
import { Canvas } from '@react-three/fiber';
import {MapControls, OrthographicCamera, PerspectiveCamera } from "@react-three/drei";
import _ from "lodash";
import Pillar from "./viz-items/pillar";
import Walls from "./viz-items/wall";
import {ENUM_CARGO_TYPES, ENUM_STORAGE_METHODS, VIZ_TYPES} from "../../../utils";
import StorageItem from "./viz-items/storage_item";
import Floor from "./viz-items/floor";
import SizeArrows from "./viz-items/sizeArrows";

const Viz3d = ({ inputs, newOutput, vizType, transformPos, transformRot, transformScale }) => {
  const {
    pillarSpanWidth,
    pillarSpanDepth,
    fireCompartmentWidthSections,
    fireCompartmentDepthSections,
    pillarSizeWidth,
    pillarSizeDepth,
    ceilingHeight,
    cargoType,
    // cargoOption,
    storageMethod,
  } = inputs;

  const floorWidth = pillarSpanWidth * fireCompartmentWidthSections;
  const floorDepth = pillarSpanDepth * fireCompartmentDepthSections;

  const {
    pallet_direct,
    pallet_pr,
    pallet_nr,
    rollbox_direct,
    piece_mdr,
    folding_container,
    cargoSpecs,
  } = newOutput;

  let vizToShow = "";

  if(cargoType === ENUM_CARGO_TYPES.pallet) {
    if(storageMethod === ENUM_STORAGE_METHODS.pallet_rack) {
      vizToShow = pallet_pr;
    } else if(storageMethod === ENUM_STORAGE_METHODS.nesting_rack) {
      vizToShow = pallet_nr;
    } else {
      vizToShow = pallet_direct;
    }
  } else if(cargoType === ENUM_CARGO_TYPES.rollbox_pallet) {
    vizToShow = rollbox_direct;
  } else if(storageMethod === ENUM_STORAGE_METHODS.medium_duty_rack) {
    vizToShow = piece_mdr;
  } else if(cargoType === ENUM_CARGO_TYPES.piece) {
    vizToShow = folding_container
  } else {
    vizToShow = pallet_direct;
  }

  const {
    storageItems,
    levels,
  } = vizToShow;

  return (
    <Canvas style={{ width: "100%", height: "100%", border: "2px solid #efefef" }}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Floor width={floorWidth} depth={floorDepth} />
      {
        vizType === VIZ_TYPES["2d"] ? (
          <OrthographicCamera
            makeDefault
            position={[floorWidth / 2, 10, floorDepth / 2]}
            zoom={8}
          />
        ) : (
          <PerspectiveCamera makeDefault position={[floorWidth / 2, 20, floorDepth + 12]} />
        )
      }
      <MapControls enableDamping={false} enableRotate={vizType === VIZ_TYPES["3d"]} target={[floorWidth / 2, 0, floorDepth / 2]} />

      {/* Walls */}
      <Walls inputs={inputs} />
      <SizeArrows
        pillarSpanWidth={pillarSpanWidth}
        pillarSpanDepth={pillarSpanDepth}
      />

      { /* Pallets */}
      {
        storageItems.length > 0 ? _.map(storageItems, (s, index) => (
          _.map(_.times(levels), l => (
            <StorageItem
              key={`${index}-${l}`}
              item={s}
              index={l}
              maxLevels={levels}
              cargoSpecs={cargoSpecs}
              transformPos={transformPos}
              transformRot={transformRot}
              transformScale={transformScale}
              storageMethod={storageMethod}
              inputs={inputs}
            />
          ))
        )): null
      }

      {
        _.map(_.times(_.toNumber(fireCompartmentWidthSections) + 1), widthPillarIndex => (
          _.map(_.times(_.toNumber(fireCompartmentDepthSections) + 1), depthPillarIndex => (
            <Pillar
              key={`${widthPillarIndex}-${depthPillarIndex}`}
              width={pillarSizeWidth}
              depth={pillarSizeDepth}
              height={ceilingHeight} // TODO: Change to WDH array later
              pos={[(widthPillarIndex * pillarSpanWidth), (depthPillarIndex * pillarSpanDepth)]}
            />
          ))
        ))
      }
    </Canvas>
  );
}

export default Viz3d;
