import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const ErrorMessage = (props) => {
  const { t } = useTranslation()
  const { i18nKey } = props

  return (
    <div className='login-error'>
      <Trans t={t} i18nKey={i18nKey} />
    </div>
  )
}

export default ErrorMessage
