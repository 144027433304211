import _ from "lodash";
import {
  ENUM_CARGO_OPTIONS,
  ENUM_CARGO_TYPES, ENUM_CARGO_VIZ_TYPES,
  ENUM_STORAGE_METHODS, ENUM_STORAGE_SIZE_TYPES,
} from "./index";

export const getVizTypeForCargoAndStorage = ({
  cargoType,
  cargoOption,
  columnSize,
  rowSize,
  storageMethod,
}) => {
  // Handle cases of storage method
  if(storageMethod === ENUM_STORAGE_METHODS.nesting_rack) {
    if(columnSize === ENUM_STORAGE_SIZE_TYPES.SINGLE) {
      if(rowSize === ENUM_STORAGE_SIZE_TYPES.SINGLE) {
        return ENUM_CARGO_VIZ_TYPES.NESTING_RACK_1P_1S
      } else {
        return ENUM_CARGO_VIZ_TYPES.NESTING_RACK_2P_1S
      }
    } else {
      if(rowSize === ENUM_STORAGE_SIZE_TYPES.SINGLE) {
        return ENUM_CARGO_VIZ_TYPES.NESTING_RACK_1P_2S
      } else {
        return ENUM_CARGO_VIZ_TYPES.NESTING_RACK_2P_2S
      }
    }
  }

  if(storageMethod === ENUM_STORAGE_METHODS.pallet_rack) {
    if(columnSize === ENUM_STORAGE_SIZE_TYPES.SINGLE) {
      if(rowSize === ENUM_STORAGE_SIZE_TYPES.SINGLE) {
        return ENUM_CARGO_VIZ_TYPES.PALLET_RACK_1P_1S
      } else {
        return ENUM_CARGO_VIZ_TYPES.PALLET_RACK_2P_1S
      }
    } else {
      if(rowSize === ENUM_STORAGE_SIZE_TYPES.SINGLE) {
        return ENUM_CARGO_VIZ_TYPES.PALLET_RACK_1P_2S
      } else {
        return ENUM_CARGO_VIZ_TYPES.PALLET_RACK_2P_2S
      }
    }
  }

  if(storageMethod === ENUM_STORAGE_METHODS.folding_containers) {
    if(columnSize === ENUM_STORAGE_SIZE_TYPES.SINGLE) {
      return ENUM_CARGO_VIZ_TYPES.FOLDING_CONTAINER_1S;
    } else {
      return ENUM_CARGO_VIZ_TYPES.FOLDING_CONTAINER_2S;
    }
  }

  if(storageMethod === ENUM_STORAGE_METHODS.carton_cases) {
    if(columnSize === ENUM_STORAGE_SIZE_TYPES.SINGLE) {
      return ENUM_CARGO_VIZ_TYPES.CARTON_BOX_1S;
    } else {
      return ENUM_CARGO_VIZ_TYPES.CARTON_BOX_2S;
    }
  }

  if(storageMethod === ENUM_STORAGE_METHODS.medium_duty_rack) {
    if(columnSize === ENUM_STORAGE_SIZE_TYPES.SINGLE) {
      if(rowSize === ENUM_STORAGE_SIZE_TYPES.SINGLE) {
        return ENUM_CARGO_VIZ_TYPES.MDR_1W_1S
      } else {
        return ENUM_CARGO_VIZ_TYPES.MDR_2W_1S
      }
    } else {
      if(rowSize === ENUM_STORAGE_SIZE_TYPES.SINGLE) {
        return ENUM_CARGO_VIZ_TYPES.MDR_1W_2S
      } else {
        return ENUM_CARGO_VIZ_TYPES.MDR_2W_2S
      }
    }
  }

  // Direct storage. Storage method is irrelevant
  if(cargoType === ENUM_CARGO_TYPES.pallet) {
    return ENUM_CARGO_VIZ_TYPES.PALLET
  } else if(cargoType === ENUM_CARGO_TYPES.rollbox_pallet) {
    if(cargoOption === ENUM_CARGO_OPTIONS.rollbox_1) {
      return ENUM_CARGO_VIZ_TYPES.ROLLBOX_PALLET_1;
    } else {
      return ENUM_CARGO_VIZ_TYPES.ROLLBOX_PALLET_2;
    }
  }
};

export const getIntermediateColumns = ({
  left, right, singleWidth, doubleWidth, subPassagewayWidth,
  clearanceType,
}) => {
  const columns = [];
  const spaceWidth = right - left;
  const nOfDoubleColumns = Math.floor((spaceWidth - subPassagewayWidth) / (doubleWidth + subPassagewayWidth));
  const nOfSingleColumns = Math.floor(
    (spaceWidth - subPassagewayWidth - nOfDoubleColumns * (doubleWidth + subPassagewayWidth)) / (singleWidth + subPassagewayWidth)
  );
  const modifiedPassagewayWidth = (spaceWidth - nOfDoubleColumns * doubleWidth - nOfSingleColumns * singleWidth) / (nOfSingleColumns + nOfDoubleColumns + 1);
  for(let i = 0; i < nOfDoubleColumns; i++) {
    columns.push({
      size: ENUM_STORAGE_SIZE_TYPES.DOUBLE,
      left: left + modifiedPassagewayWidth + i * (modifiedPassagewayWidth + doubleWidth),
      type: clearanceType,
    });
  }
  for(let i = 0; i < nOfSingleColumns; i++) {
    columns.push({
      size: ENUM_STORAGE_SIZE_TYPES.SINGLE,
      left: left + modifiedPassagewayWidth + nOfDoubleColumns * (modifiedPassagewayWidth + doubleWidth) + i * (modifiedPassagewayWidth + singleWidth),
      type: clearanceType,
    });
  }
  return columns;
};

export const getItemsInArea = ({
  size: columnSize,
  top,
  left,
  bottom,
  doubleDepth,
  singleDepth,
  doubleWidth,
  singleWidth,
  height,
  storageMethod,
  rotation,
}) => {
  const items = [];
  const totalHeight = bottom - top;
  const nOfDoubleSize = Math.floor(totalHeight / doubleDepth);
  const nOfSingleSize = Math.floor((totalHeight - nOfDoubleSize * doubleDepth)/singleDepth);
  const remainingHeight = totalHeight - nOfDoubleSize * doubleDepth - nOfSingleSize * singleDepth;
  for(let i = 0; i < nOfDoubleSize; i++) {
    items.push({
      left,
      top: top + remainingHeight / 2 + i * doubleDepth,
      viz_type: getVizTypeForCargoAndStorage({
        storageMethod,
        columnSize,
        rowSize: ENUM_STORAGE_SIZE_TYPES.DOUBLE,
      }),
      width: columnSize === ENUM_STORAGE_SIZE_TYPES.SINGLE ? singleWidth :  doubleWidth,
      depth: doubleDepth,
      height,
      rotation: rotation || 0,
    });
  }
  for(let i = 0; i < nOfSingleSize; i++) {
    items.push({
      left,
      top: top + remainingHeight / 2 + nOfDoubleSize * doubleDepth + i * singleDepth,
      viz_type: getVizTypeForCargoAndStorage({
        storageMethod,
        columnSize,
        rowSize: ENUM_STORAGE_SIZE_TYPES.SINGLE,
      }),
      width: columnSize === ENUM_STORAGE_SIZE_TYPES.SINGLE ? singleWidth :  doubleWidth,
      depth: singleDepth,
      height,
      rotation: rotation || 0,
    });
  }
  return items;
};

export const transformSanitizeNumbers = (inputs) => _.transform(inputs,
  (result, value, key) => {
  if(_.isObject(value)) {
    result[key] = transformSanitizeNumbers(value);
  } else if(_.includes(value, ",")) {
    result[key] = _.parseInt(_.replace(value, ",", ""));
  } else if(_.includes(value, ".")) {
    result[key] = parseFloat(value);
  } else if(!isNaN(_.parseInt(value))){
    result[key] = parseInt(value);
  } else {
    result[key] = value;
  }
}, {});

export const getSubPassagewayLeft = ({
  spaceEdge,
  index,
  adjustedSubPassagewayWidth,
  bulkUnitWidth,
  edgeUnitWidth,
}) => {
  if(index === 0) {
    return spaceEdge + edgeUnitWidth;
  }
  return getSubPassagewayLeft({
    index: index - 1,
    spaceEdge, adjustedSubPassagewayWidth, bulkUnitWidth, edgeUnitWidth
  }) + adjustedSubPassagewayWidth + bulkUnitWidth;
}