import axios from 'axios'
import {BACKEND_HOST} from './index'
import Cookies from 'js-cookie'

const authAxios = (options) => {
  const token = Cookies.get('token')
  return axios({
    ...options,
    baseURL: BACKEND_HOST,
    headers: {
      ...(options.headers || {}),
      Authorization: `Bearer ${token}`,
    },
  })
}

/*********************
      Login page
*********************/
export const apiLoginUser = (email, password, token) =>
  axios({
    method: 'POST',
    baseURL: BACKEND_HOST,
    url: '/user/login',
    data: { email, password, token },
  })

/*********************
      Plans pages
*********************/
export const apiGetPlanDetails = (planId) =>
  authAxios({
    method: 'GET',
    url: `/asokoplus/plans/${planId}`,
  })

export const apiUpdatePlanInputs = (planId, inputs) =>
  authAxios({
    method: 'POST',
    url: `/asokoplus/plans/${planId}`,
    data: {
      inputs,
    },
  })

/*********************
      Users page
*********************/
export const apiGetAllUsers = () =>
  authAxios({
    method: 'GET',
    url: `/user/all`,
  })

export const apiGetUserFromId = (user_id) =>
  authAxios({
    method: 'GET',
    url: `/user/get-details/${user_id}`,
  })

export const apiGetUserFromCompanyId = (company_id) =>
  authAxios({
    method: 'GET',
    url: `/user/get-details/company-id/${company_id}`,
  })

export const apiCreateUser = ({
  email,
  password,
  lang,
  first_name,
  last_name,
  role,
  company_id,
  is_notification
}) =>
  axios({
    method: 'POST',
    baseURL: BACKEND_HOST,
    url: '/user/create',
    data: {
      email,
      password,
      lang,
      first_name,
      last_name,
      role,
      company_id,
      is_notification
    },
  })

export const apiUpdateUserDetails = ({
  first_name,
  last_name,
  password,
  email,
  role,
  company_id,
  is_notification,
  user_id
}) =>
  authAxios({
    method: 'POST',
    url: `/user/update`,
    data: {
      first_name,
      last_name,
      password,
      email,
      role,
      company_id,
      is_notification,
      user_id
    },
  })

export const apiDeleteUser = ({ id }) =>
  authAxios({
    method: 'POST',
    url: `/user/delete`,
    data: { id },
  })

/*********************
      Companies page
*********************/
export const apiGetAllCompanies = () =>
  authAxios({
    method: 'GET',
    url: `/company/all`,
  })

export const apiCreateCompany = ({ name, short_name }) =>
  axios({
    method: 'POST',
    baseURL: BACKEND_HOST,
    url: '/company/create',
    data: { name, short_name },
  })

export const apiGetCompanyFromId = (company_id) =>
  authAxios({
    method: 'GET',
    url: `/company/get-details/${company_id}`,
  })

export const apiUpdateCompanyDetails = ({ name, short_name, id }) =>
  authAxios({
    method: 'POST',
    url: `/company/update`,
    data: {
      name,
      short_name,
      id
    },
  })

export const apiDeleteCompany = ({ id }) =>
  authAxios({
    method: 'DELETE',
    url: `/company/delete`,
    data: { id },
  })

  /*********************
      Warehouse page
*********************/
export const apiGetAllWarehouses = () =>
  authAxios({
    method: 'GET',
    url: `/warehouse/all`,
  })

export const apiCreateWarehouse = ({ name, short_name, company_id }) =>
  axios({
    method: 'POST',
    baseURL: BACKEND_HOST,
    url: '/warehouse/create',
    data: { name, short_name, company_id },
  })

export const apiUpdateWarehouseDetails = ({
  name,
  short_name,
  company_id,
  id
}) =>
  authAxios({
    method: 'POST',
    url: `/warehouse/update`,
    data: {
      name,
      short_name,
      company_id,
      id
    },
  })

  export const apiGetWarehouseFromId = (id) =>
    authAxios({
      method: 'GET',
      url: `/warehouse/get-details/warehouse-id/${id}`,
    })

export const apiGetWarehouseFromCompanyId = (company_id) =>
  authAxios({
    method: 'GET',
    url: `/warehouse/get-details/${company_id}`,
  })

export const apiDeleteWarehouse = ({ id }) =>
  authAxios({
    method: 'DELETE',
    url: `/warehouse/delete`,
    data: { id },
  })

/*********************
  Warehouse Assign
*********************/
export const apiCreateWarehouseAssign = ({ user_id, warehouse_id }) =>
  axios({
    method: 'POST',
    baseURL: BACKEND_HOST,
    url: '/warehouse-assign/create',
    data: { user_id, warehouse_id },
  })

export const apiGetWarehouseAssignFromUserId = (userId) =>
  authAxios({
    method: 'GET',
    url: `/warehouse-assign/get-details/${userId}`,
  })

export const apiDeleteWarehouseAssignFromUserId = ({ user_id }) =>
  authAxios({
    method: 'DELETE',
    url: `/warehouse-assign/delete-user_id`,
    data: { user_id },
  })

export const apiUpdateWarehouseAssignFromUserId = ({user_id, warehouse_list}) =>
    authAxios({
      method: 'POST',
      url:`/warehouse-assign/update`,
      data: {user_id, warehouse_list},
    })

/*********************
      Asoko2 page
*********************/
export const apiGetAsoko2SimulationModels = (warehouse_id) =>
  authAxios({
    method: 'GET',
    url: `/asoko2/simulation/models/${warehouse_id}`,
  })

export const apiGetAsoko2SimulationMasters = (warehouse_id) =>
  authAxios({
    method: 'GET',
    url: `/asoko2/simulation/masters/${warehouse_id}`,
  })

export const apiGetAsoko2SimulationResults = (warehouse_id) =>
  authAxios({
    method: 'GET',
    url: `/asoko2/simulation/results/${warehouse_id}`,
  })

export const apiAsoko2SimulationExec = ({ warehouse_id, account, userFirstName, userLastName, excelFlag }) =>
  authAxios({
    method: 'POST',
    url: `/asoko2/simulation/exec`,
    data: { warehouse_id, account, userFirstName, userLastName, excelFlag },
  })

export const apiAsoko2SimulationUpload = async (formData) => {
  await axios.post(BACKEND_HOST + '/asoko2/simulation/upload', formData, {
    headers: {
        'Content-Type': 'multipart/form-datacharset=UTF-8',
        'Access-Control-Allow-Origin': '*',
    },
  })
    .then((response) => {
      // console.log('アップロード成功', response.data)
    })
    .catch((error) => {
      // console.error('アップロード失敗', error)
    })
  }

export const apiGetAsoko2PickingModels = (warehouse_id) =>
  authAxios({
    method: 'GET',
    url: `/asoko2/picking/models/${warehouse_id}`,
  })

export const apiGetAsoko2PickingMasters = (warehouse_id) =>
  authAxios({
    method: 'GET',
    url: `/asoko2/picking/masters/${warehouse_id}`,
  })

export const apiGetAsoko2PickingResults = (warehouse_id) =>
  authAxios({
    method: 'GET',
    url: `/asoko2/picking/results/${warehouse_id}`,
  })

export const apiAsoko2PickingExec = ({ warehouse_id, account, userFirstName, userLastName, excelFlag }) =>
  authAxios({
    method: 'POST',
    url: `/asoko2/picking/exec`,
    data: { warehouse_id, account, userFirstName, userLastName, excelFlag },
  })

export const apiAsoko2PickingUpload = async (formData) => {
  await axios.post(BACKEND_HOST + '/asoko2/picking/upload', formData, {
    headers: {
        'Content-Type': 'multipart/form-datacharset=UTF-8',
        'Access-Control-Allow-Origin': '*',
    },
  })
    .then((response) => {
      console.log('アップロード成功', response.data)
    })
    .catch((error) => {
      console.error('アップロード失敗', error)
    })
  }
