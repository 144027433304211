import React from "react"
import { Trans, withTranslation, useTranslation } from 'react-i18next'

const Label = (props) => {
  const { t } = useTranslation()
  const { type='default', i18nKey='', value=null, inputFor='' } = props

  const className = () => {
    switch(type) {
      case 'input':
        return 'input-label'

      default:
        return ''
    }
  }

  return (
    <label className={className()}>
      {value !== null ? (
        <label htmlFor={inputFor}>{value}</label>
        ) : (
          <label htmlFor={inputFor}>
            <Trans t={t} i18nKey={i18nKey} />
          </label>
        )
      }
    </label>
  )
}

export default withTranslation()(Label)