import React from 'react'

import { NavLink } from 'react-router-dom'

import Button from '../atoms/Button'

const ServiceButtonsSet = (props) => {
  const { backTo, handleExecute=null, role=null } = props
  const className = !!handleExecute ? '' : 'padding'
  const isDisabled = role === 'viewer'

  return (
    <div className={className}>
      <NavLink className='nav-item-link' to={backTo}>
        <Button
          className='home-menu-back-btn'
          i18nKey='back'
        />
      </NavLink>
      {!!handleExecute && (
        <Button
          className='exec-btn'
          margin='20px'
          i18nKey='asoko2exec'
          clickHandler={handleExecute}
          disabled={isDisabled}
        />
      )}
    </div>
  )
}

export default ServiceButtonsSet
