import React, { useContext, useState } from 'react'
import Cookies from "js-cookie";

import { AuthContext } from '../../app'

import Button from '../atoms/Button'
import FileUploader from '../atoms/FileUploader'
import SelectInputForm from './SelectInputForm'

const UploadModule = (props) => {
  const {
    kind,
    setIsInProgress,
    handleFileUpload,
    setErrorMessage,
    mode=''
  } = props
  const { userDetails } = useContext(AuthContext)
  const userFirstName = userDetails['first_name']
  const userLastName = userDetails['last_name']

  const [ image, setImage ] = useState(null)
  const [ selectedRowNo, setSelectedRowNo ] = useState(0)
  const [ isUploadDone, setIsUploadDone ] = useState(false)

  const optionsForSim = [
    'select_kind_of_master',
    'parameter_master',
    'merchandise_master',
    'packing_master',
    'delivery_master',
    'inventory_data',
    'shipment_data'
  ]

  const optionsForPicking = [
    'select_kind_of_master',
    'parameter_master',
    'shipment_data'
  ]

  const returnOptions = () => {
    if (mode === 'sim') return optionsForSim
    if (mode === 'picking') return optionsForPicking
    return []
  }
  const options = returnOptions()

  const handleUploaderClicked = (e) => {
    setIsUploadDone(false)
    setImage(null)
    e.target.value = ''
  }

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0]
    setImage(selectedImage)
  }

  const handleSubmit = async (e) => {
    const fileSizeLimit = 1024 * 1024 * 50  // 50MB
    const fileSize = image.size
    if (fileSize > fileSizeLimit) {
      setErrorMessage('file_size_error')
    } else {
      setErrorMessage('')
      e.preventDefault()

      const formData = new FormData()
      formData.append('file', image, encodeURIComponent(`${image.name}`))
      formData.append('kind', kind)
      const no = Number(selectedRowNo)
      formData.append('no', no)
      formData.append('userFirstName', userFirstName)
      formData.append('userLastName', userLastName)
      const cookies_warehouseId = Cookies.get('warehouse_id')

      formData.append('warehouse_id', cookies_warehouseId)

      setIsInProgress(true)

      try {
        await handleFileUpload(formData)
      } catch (e) {
        // console.log(e)
      }
      setIsInProgress(false)
      setIsUploadDone(true)
    }
  }

  const isKindOfMasterSet =
    kind === 'master'
      ? Number(selectedRowNo) !== 0
      : true

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: '7px'
    }}>
      {kind === 'master' && (
        <div style={{marginRight: '20px'}}>
          <SelectInputForm
            isNewExec={true}
            display='inline'
            value={selectedRowNo}
            onChange={setSelectedRowNo}
            options={options}
          />
        </div>
      )}
      <FileUploader
        kind={kind}
        image={image}
        handleUploaderClicked={handleUploaderClicked}
        handleImageChange={handleImageChange}
        isUploadDone={isUploadDone}
      />
      <Button
        color='primary'
        margin='10px'
        i18nKey='upload'
        clickHandler={handleSubmit}
        disabled={!image || isUploadDone || !isKindOfMasterSet}
      />
    </div>
  )
}

export default UploadModule
