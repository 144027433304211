import React from 'react'

import ServiceMenuTemplate from '../../../templates/ServiceMenuTemplate'

const ServiceMenuHome = () => {
  const props = {
    pageTitle: 'servicemenu',
    upperNavLinkTitle: 'asoko2',
    upperNavLinkSubTitle: 'asoko2cmt',
    upperNavTo: '/asoko2menu',
    lowerNavLinkTitle: 'asokoplus',
    lowerNavLinkSubTitle: 'asokopluscmt',
    lowerNavTo: '/asokoplusmenu',
  }

  return <ServiceMenuTemplate props={props} />
}

export default ServiceMenuHome
