import React  from "react";
import { useGLTF } from "@react-three/drei";

export function NR1X1Y(props) {
  const { nodes, materials } = useGLTF("/new_models/nr_1x_1y.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.nesting_rack_1.geometry}
        material={materials["Material.002"]}
      />
    </group>
  );
}

useGLTF.preload("/new_models/nr_1x_1y.glb");

export default NR1X1Y;