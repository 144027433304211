import React, { useState, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { EXEC_FETCH_INTERVAL_TIME, THRESHOLD_OF_TIMEOUT } from '../../utils/constants'

import { apiGetAsoko2PickingResults, apiGetAsoko2SimulationResults } from '../../utils/api'
import Cookies from 'js-cookie'

const MessageOfExecuteDone = (props) => {
  const {
    setIsExecProgress,
    execStatus,
    setExecStatus,
  } = props
  const [ message, setMessage ] = useState('')
  const { t } = useTranslation()

  const kind = Cookies.get('kind')
  const intervalId = useRef()

  const resetStatus = () => {
    setIsExecProgress(false)
    Cookies.set('exec_warehouse_id', '')
    Cookies.set('job_id', '')
    Cookies.set('exec_time', '')
    clearInterval(intervalId)
  }

  const checkExecProgress = async () => {
    const currentTime = new Date().getTime()
    const execTime = Cookies.get('exec_time')
    const currentExecWarehouseId = Cookies.get('exec_warehouse_id')
    const currentJobId = Cookies.get('job_id')
    const currentExecStatus = Cookies.get('exec_status')
    const timeDifference = currentTime - execTime
    const isTimeOut = !!execTime
      ? timeDifference > THRESHOLD_OF_TIMEOUT
      : false
    const isExecWarehouseIdExist =
      !((currentExecWarehouseId === 'undefined') || !currentExecWarehouseId)
    const isJobIdExist = !((currentJobId === 'undefined') || !currentJobId)
    const isTokenNull = Cookies.get('token')
    const isLogOut = isTokenNull === ('null')

    if (isLogOut) {
      setMessage('')
      setExecStatus('')
      resetStatus()
    } else if (isTimeOut) {
      setMessage('exec_has_error')
      Cookies.set('exec_status', 'done')
      setExecStatus('')
      resetStatus()
    } else if (isExecWarehouseIdExist && isJobIdExist && currentExecStatus === 'executing') {
      await fetchExecIsDone(currentExecWarehouseId, currentJobId)
    } else {
      resetStatus()
    }
  }

  const fetchExecIsDone = async (exec_warehouse_id, job_id) => {
    try {
      const {
        data: { data: results },
      } = kind === 'p'
        ? await apiGetAsoko2PickingResults(exec_warehouse_id)
        : await apiGetAsoko2SimulationResults(exec_warehouse_id)
      const filteredResults = results.filter(result => result[4] === job_id)
      if (filteredResults.length > 0) {
        const filteredResultWithNormal = filteredResults.filter(result => result[1] === 'E_00_00_00')
        if (filteredResultWithNormal.length > 0) {
          setMessage('exec_is_done')
        } else {
          setMessage('exec_has_error')
        }
        Cookies.set('exec_status', 'done')
        resetStatus()
      } else {
        setMessage('exec_in_progress')
      }
    } catch (error) {
      setMessage('exec_has_error')
      Cookies.set('exec_status', 'done')
      resetStatus()
    }
  }

  useEffect(() => {
    intervalId.current = setInterval(checkExecProgress, EXEC_FETCH_INTERVAL_TIME)
    return () => clearInterval(intervalId.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const exec_status = Cookies.get('exec_status')
    if(exec_status === '' && execStatus === 'done') {
      setMessage('')
      setExecStatus('')
    }
    Cookies.set('kind', '')
    resetStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execStatus])

  const color = message === 'exec_has_error' ? 'red' : 'black'

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '40px',
        left: '10px'
      }}
    >
      <div style={{
        color: color
      }}>
        <Trans t={t} i18nKey={message} />
      </div>
    </div>
  )
}

export default MessageOfExecuteDone
