import * as React from "react";
import {Line, Text} from "@react-three/drei";
import Triangle from "../viz-helpers/triangle";

const SizeArrows = ({
  pillarSpanWidth,
  pillarSpanDepth
}) => {
  return (
    <>
      {/*Pillar span horizontal*/}
      <Text
        scale={[1, 1, 1]}
        position={[pillarSpanWidth / 2, 0, -2]}
        rotation={[-Math.PI / 2, 0, 0]}
        color="#000000"
        anchorX="center"
        anchorY="center"
      >
        {pillarSpanWidth} m
      </Text>
      <Line
        points={[
          [0, 0, 0],
          [pillarSpanWidth, 0, 0]
        ]}
        color="#000000"
        linewidth={1}
        position={[0 , 0, -1]}
      />
      <Triangle
        vertices={[
          [pillarSpanWidth, 0, -1],
          [pillarSpanWidth - 0.4, 0, -1.4],
          [pillarSpanWidth - 0.4, 0, -0.6],
        ]}
      />
      <Triangle
        vertices={[
          [0, 0, -1],
          [0.4, 0, -1.4],
          [0.4, 0, -0.6],
        ]}
      />

      {/*Pillar span vertical*/}
      <Text
        scale={[1, 1, 1]}
        position={[-2, 0, pillarSpanDepth / 2]}
        rotation={[-Math.PI / 2, 0, Math.PI/2]}
        color="#000000"
        anchorX="center"
        anchorY="center"
      >
        {pillarSpanDepth} m
      </Text>
      <Line
        points={[
          [0, 0, 0],
          [0, 0, pillarSpanDepth]
        ]}
        color="#000000"
        linewidth={1}
        position={[-1 , 0, 0]}
      />
      <Triangle
        vertices={[
          [-1, 0, pillarSpanDepth],
          [-1.4, 0, pillarSpanDepth - 0.4],
          [-0.6, 0, pillarSpanDepth - 0.4],
        ]}
      />
      <Triangle
        vertices={[
          [-1, 0, 0],
          [-1.4, 0, 0.4],
          [-0.6, 0, 0.4],
        ]}
      />
    </>
  )
};

export default SizeArrows;