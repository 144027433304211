import React from 'react'

import { NavLink } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

const ServiceMenuCard = (props) => {
  const { t } = useTranslation()
  const {
    navTo='',
    navLinkTitle='',
    navLinkSubTitle='',
  } = props

  const isDisable = !navTo

  return (
    <div className='padding'>
      <NavLink className='asoko-nav-item-link' to={navTo}>
        <button
          className={`home-menu-btn${isDisable ? '-gray' : ''}`}
          style={{
            borderRadius: '8px',
            background: 'linear-gradient(90deg, #B67B03 0%, #DAAF08 45%, #F0E68C 80%, #DAAF08 85%, #B67B03 90% 100%)'
          }}
        >
          <span>
            <Trans t={t} i18nKey={navLinkTitle} />
            <span>
              <Trans t={t} i18nKey={navLinkSubTitle} />
            </span>
          </span>
        </button>
      </NavLink>
    </div>
  )
}

export default ServiceMenuCard
