import {useState} from "react";

const Collapsible = ({ children, defaultOpen, heading }) => {
  const [collapsed, setCollapsed] = useState(!defaultOpen);
  return (
    <>
      <div className="collapsible">
        <button className="collapsible-caret" onClick={() => setCollapsed(!collapsed)}>
          <span className="material-icons">
            { collapsed ? 'arrow_right' : 'arrow_drop_down' }
          </span>
          { heading }
        </button>
      </div>
      {
        !collapsed && children
      }
    </>
  );
}

export default Collapsible;
