import React from "react";
import _ from "lodash";

import Pallet from "./pallet";
import Carton from "./carton";
import { ENUM_CARGO_VIZ_TYPES, ENUM_STORAGE_METHODS } from "../../../../utils";
import RollboxPallet from "./rollbox_pallet";
import PalletRack1P1S from "./pallet_rack_1p_1s";
import PalletRack1P2S from "./pallet_rack_1p_2s";
import PalletRack2P1S from "./pallet_rack_2p_1s";
import PalletRack2P2S from "./pallet_rack_2p_2s";
import CartonBox from "./carton_box";
import CartonBox2 from "./carton_box_2";
import FoldingContainer from "./folding_container";
import FoldingContainer2 from "./folding_container_2";
import Mdr1W1S from "./mdr_1w_1s";
import Mdr1W2S from "./mdr_1w_2s";
import Mdr2W1S from "./mdr_2w_1s";
import Mdr2W2S from "./mdr_2w_2s";
import NR1X1Y from "./nr_1x_1y";
import NR1X2Y from "./nr_1x_2y";
import NR2X1Y from "./nr_2x_1y";
import NR2X2Y from "./nr_2x_2y";
// import {Box} from "@react-three/drei";

const MODEL_DIMENSIONS = {
  PALLET: [1.1, 1.1, 0.15],
  CARTON: [1, 1, 0.85],
  ROLLBOX_PALLET: [1.1, 0.8, 1.7],
  ROLLBOX_PALLET_2: [1.1, 0.8, 1],
  NESTING_RACK_1P_1S: [1.35, 1.2, 1.6],
  NESTING_RACK_1P_2S: [1.35, 2.4, 1.6],
  NESTING_RACK_2P_1S: [2.7, 1.2, 1.6],
  NESTING_RACK_2P_2S: [2.7, 2.4, 1.6],
  NESTING_RACK: [1, 1, 1],
  PALLET_RACK_1P_1S: [1.41, 1.1, 1.833],
  PALLET_RACK_2P_1S: [2.61, 1.1, 1.833],
  PALLET_RACK_1P_2S: [1.41, 2.3, 1.833],
  PALLET_RACK_2P_2S: [2.61, 2.3, 1.833],
  MDR_1W_1S: [0.9, 0.45, 0.35],
  MDR_2W_1S: [1.8, 0.45, 0.35],
  MDR_1W_2S: [0.9, 0.93, 0.35],
  MDR_2W_2S: [1.8, 0.93, 0.35],
  FOLDING_CONTAINER_1S: [0.65, 0.44, 0.33],
  FOLDING_CONTAINER_2S: [0.65, 0.88, 0.33],
  CARTON_BOX_1S: [0.65, 0.44, 0.33],
  CARTON_BOX_2S: [0.65, 0.88, 0.33],
};

const ROTATE = {
  PALLET: 0,
  ROLLBOX_PALLET_1: 0,
  ROLLBOX_PALLET_2: 0,
  NESTING_RACK_1P_1S: Math.PI / 2,
  NESTING_RACK_1P_2S: Math.PI / 2,
  NESTING_RACK_2P_1S: Math.PI / 2,
  NESTING_RACK_2P_2S: Math.PI / 2,
  PALLET_RACK_1P_1S: Math.PI / 2,
  PALLET_RACK_2P_1S: Math.PI / 2,
  PALLET_RACK_1P_2S: Math.PI / 2,
  PALLET_RACK_2P_2S: Math.PI / 2,
  MDR_1W_1S: Math.PI / 2,
  MDR_2W_1S: Math.PI / 2,
  MDR_1W_2S: Math.PI / 2,
  MDR_2W_2S: Math.PI / 2,
  FOLDING_CONTAINER_1S: Math.PI / 2,
  FOLDING_CONTAINER_2S: Math.PI / 2,
  CARTON_BOX_1S: Math.PI / 2,
  CARTON_BOX_2S: Math.PI / 2,
}

const MODELS = {
  // [ENUM_CARGO_VIZ_TYPES.PALLET]: Pallet,
  // [ENUM_CARGO_VIZ_TYPES.ROLLBOX_PALLET_1]: RollboxPallet,
  // [ENUM_CARGO_VIZ_TYPES.ROLLBOX_PALLET_2]: RollboxPallet,
  [ENUM_CARGO_VIZ_TYPES.NESTING_RACK_1P_1S]: NR1X1Y,
  [ENUM_CARGO_VIZ_TYPES.NESTING_RACK_1P_2S]: NR1X2Y,
  [ENUM_CARGO_VIZ_TYPES.NESTING_RACK_2P_1S]: NR2X1Y,
  [ENUM_CARGO_VIZ_TYPES.NESTING_RACK_2P_2S]: NR2X2Y,
  [ENUM_CARGO_VIZ_TYPES.PALLET_RACK_1P_1S]: PalletRack1P1S,
  [ENUM_CARGO_VIZ_TYPES.PALLET_RACK_2P_1S]: PalletRack2P1S,
  [ENUM_CARGO_VIZ_TYPES.PALLET_RACK_1P_2S]: PalletRack1P2S,
  [ENUM_CARGO_VIZ_TYPES.PALLET_RACK_2P_2S]: PalletRack2P2S,
  [ENUM_CARGO_VIZ_TYPES.MDR_1W_1S]: Mdr1W1S,
  [ENUM_CARGO_VIZ_TYPES.MDR_2W_1S]: Mdr2W1S,
  [ENUM_CARGO_VIZ_TYPES.MDR_1W_2S]: Mdr1W2S,
  [ENUM_CARGO_VIZ_TYPES.MDR_2W_2S]: Mdr2W2S,
  [ENUM_CARGO_VIZ_TYPES.FOLDING_CONTAINER_1S]: FoldingContainer,
  [ENUM_CARGO_VIZ_TYPES.FOLDING_CONTAINER_2S]: FoldingContainer2,
  [ENUM_CARGO_VIZ_TYPES.CARTON_BOX_1S]: CartonBox,
  [ENUM_CARGO_VIZ_TYPES.CARTON_BOX_2S]: CartonBox2,
};

const getStorageCargoPositions = (vizType) => {
  switch (vizType){
    case ENUM_CARGO_VIZ_TYPES.NESTING_RACK_1P_1S:
    case ENUM_CARGO_VIZ_TYPES.PALLET_RACK_1P_1S:
      return [
        {x: 0.5, y: 0, z: 0.5},
      ];
    case ENUM_CARGO_VIZ_TYPES.NESTING_RACK_1P_2S:
    case ENUM_CARGO_VIZ_TYPES.PALLET_RACK_1P_2S:
      return [
        {x: 0.75, y: 0, z: 0.5},
        {x: 0.25, y: 0, z: 0.5},
      ];
    case ENUM_CARGO_VIZ_TYPES.NESTING_RACK_2P_1S:
    case ENUM_CARGO_VIZ_TYPES.PALLET_RACK_2P_1S:
      return [
        {x: 0.5, y: 0, z: 0.75},
        {x: 0.5, y: 0, z: 0.25},
      ];
    case ENUM_CARGO_VIZ_TYPES.PALLET_RACK_2P_2S:
    case ENUM_CARGO_VIZ_TYPES.NESTING_RACK_2P_2S:
      return [
        {x: 0.25, y: 0, z: 0.25},
        {x: 0.25, y: 0, z: 0.75},
        {x: 0.75, y: 0, z: 0.25},
        {x: 0.75, y: 0, z: 0.75},
      ];
    default:
      return [];
  }
};

const getStorageBoxPositions = (vizType, inputs, storageHeight) => {
  const {
    storageSpecs: {
      mdrRows1w1s,
      mdrRows2w1s,
      mdrRows2w2s,
      mdrRows1w2s,
      mdrSingleWidth,
      mdrSingleDepth,
      mdrDoubleWidth,
      mdrDoubleDepth,
    },
  } = inputs;
  switch (vizType){
    case ENUM_CARGO_VIZ_TYPES.MDR_1W_1S:
      return _.map(_.times(mdrRows1w1s), m => ({
        x: 0.5, y: 0, z: (2 * m + 1)/(mdrRows1w1s * 2),
        width: 0.8 * mdrSingleWidth / mdrRows1w1s, height: storageHeight * 0.5, depth: mdrSingleDepth * 0.6,
      }));
    case ENUM_CARGO_VIZ_TYPES.MDR_2W_1S:
      return _.map(_.times(mdrRows2w1s), m => ({
        x: 0.5, y: 0, z: (2 * m + 1)/(mdrRows2w1s * 2),
        width: 0.8 * mdrDoubleWidth / mdrRows2w1s, height: storageHeight * 0.5, depth: mdrSingleDepth * 0.6,
      }));
    case ENUM_CARGO_VIZ_TYPES.MDR_1W_2S: {
      const col1Count = Math.floor(mdrRows1w2s / 2);
      const col2Count = Math.ceil(mdrRows1w2s / 2);
      return [
        ...(_.map(_.times(col1Count), m => ({
          x: 0.25, y: 0, z: (2 * m + 1)/(col1Count * 2),
          width: 0.8 * mdrSingleWidth / col1Count, height: storageHeight * 0.5, depth: mdrDoubleDepth * 0.6 / 2,
        }))),
        ...(_.map(_.times(col2Count), m => ({
          x: 0.75, y: 0, z: (2 * m + 1)/(col2Count * 2),
          width: 0.8 * mdrSingleWidth / col2Count, height: storageHeight * 0.5, depth: mdrDoubleDepth * 0.6 / 2,
        })))
      ];
    }
    case ENUM_CARGO_VIZ_TYPES.MDR_2W_2S: {
      const col1Count = Math.floor(mdrRows2w2s / 2);
      const col2Count = Math.ceil(mdrRows2w2s / 2);
      return [
        ...(_.map(_.times(col1Count), m => ({
          x: 0.25, y: 0, z: (2 * m + 1)/(col1Count * 2),
          width: 0.8 * mdrDoubleWidth / col1Count, height: storageHeight * 0.5, depth: mdrDoubleDepth * 0.6 / 2,
        }))),
        ...(_.map(_.times(col2Count), m => ({
          x: 0.75, y: 0, z: (2 * m + 1)/(col2Count * 2),
          width: 0.8 * mdrDoubleWidth / col2Count, height: storageHeight * 0.5, depth: mdrDoubleDepth * 0.6 / 2,
        })))
      ];
    }
    default:
      return [];
  }
};

const StorageItem = ({
  cargoSpecs,
  item,
  index,
  transformRot,
  transformPos,
  transformScale,
  maxLevels,
  storageMethod,
  inputs,
}) => {
  const pos = { x: item.left, y: index * item.height, z: item.top };
  const type = item.viz_type;

  const StorageComponent = MODELS[type];

  // Direct storage pallet
  if(
    _.includes([
      ENUM_CARGO_VIZ_TYPES.PALLET,
    ], type)
  ) {
    return (
      <>
        <Carton
          scale={[
            (cargoSpecs.width - 0.04) / MODEL_DIMENSIONS.CARTON[0],
            (cargoSpecs.height - 0.15) / MODEL_DIMENSIONS.CARTON[2],
            (cargoSpecs.depth - 0.04) / MODEL_DIMENSIONS.CARTON[1],
          ]}
          position={transformPos(
            pos.x + cargoSpecs.width / 2,
            pos.y + 0.15,
            pos.z + cargoSpecs.depth / 2,
          )}
          rotation={[0, transformRot(ROTATE[type]), 0]}
        />
        <Pallet
          scale={[
            cargoSpecs.width / MODEL_DIMENSIONS.PALLET[0],
            0.15 / MODEL_DIMENSIONS.PALLET[2],
            cargoSpecs.depth / MODEL_DIMENSIONS.PALLET[1],
          ]}
          position={transformPos(
            pos.x + cargoSpecs.width / 2,
            pos.y,
            pos.z + cargoSpecs.depth / 2,
          )}
          rotation={[0, transformRot(ROTATE[type]), 0]}
        />
      </>
    );
  }

  // Direct storage rollbox
  if(
    _.includes([
      ENUM_CARGO_VIZ_TYPES.ROLLBOX_PALLET_1,
      ENUM_CARGO_VIZ_TYPES.ROLLBOX_PALLET_2,
    ], type)
  ) {
    return (
      <>
        <Carton
          scale={[
            (cargoSpecs.width - 0.1) / MODEL_DIMENSIONS.CARTON[0],
            (cargoSpecs.height) / MODEL_DIMENSIONS.CARTON[2],
            (cargoSpecs.depth - 0.1) / MODEL_DIMENSIONS.CARTON[1],
          ]}
          position={transformPos(
            pos.x + cargoSpecs.width / 2,
            pos.y + 0.18,
            pos.z + cargoSpecs.depth / 2,
          )}
          rotation={[0, transformRot(ROTATE[type]), 0]}
        />
        <RollboxPallet
          scale={[
            cargoSpecs.width / MODEL_DIMENSIONS.ROLLBOX_PALLET[0],
            cargoSpecs.height / MODEL_DIMENSIONS.ROLLBOX_PALLET[2],
            cargoSpecs.depth / MODEL_DIMENSIONS.ROLLBOX_PALLET[1],
          ]}
          position={transformPos(
            pos.x + cargoSpecs.width / 2,
            pos.y,
            pos.z + cargoSpecs.depth / 2,
          )}
          rotation={[0, transformRot(ROTATE[type]), 0]}
        />
      </>
    )
  }

  if(StorageComponent) {
    const cargoPositions = getStorageCargoPositions(type);
    const hasCargoOnTop = (maxLevels - 1 === index) && storageMethod === ENUM_STORAGE_METHODS.nesting_rack;
    const cargoPosModified = hasCargoOnTop ? [
      ...cargoPositions,
      ...(cargoPositions.map(c => ({ ...c, y: 1 })))
    ] : cargoPositions;

    const boxPositions = getStorageBoxPositions(type, inputs, item.height);

    return <>
      {
        _.map(cargoPosModified, (c, i) => {
          return <React.Fragment key={i}>
            <Carton
              scale={[
                (cargoSpecs.width - 0.04) / MODEL_DIMENSIONS.CARTON[0],
                (cargoSpecs.height - 0.15) / MODEL_DIMENSIONS.CARTON[2],
                (cargoSpecs.depth - 0.04) / MODEL_DIMENSIONS.CARTON[1],
              ]}
              position={transformPos(
                pos.x + c.x * item.width,
                pos.y + item.height * c.y + 0.15,
                pos.z + c.z * item.depth,
              )}
              rotation={[0, transformRot(ROTATE[type]), 0]}
            />
            <Pallet
              key={i}
              scale={[
                cargoSpecs.width / MODEL_DIMENSIONS.PALLET[0],
                0.15 / MODEL_DIMENSIONS.PALLET[2],
                cargoSpecs.depth / MODEL_DIMENSIONS.PALLET[1],
              ]}
              position={transformPos(
                pos.x + c.x * item.width,
                pos.y + item.height * c.y,
                pos.z + c.z * item.depth,
              )}
              rotation={[0, transformRot(ROTATE[type]), 0]}
            />
          </React.Fragment>
        })
      }
      {
        boxPositions.map((b, i) => (
          <Carton
            key={i}
            scale={[
              b.width / MODEL_DIMENSIONS.CARTON[0],
              b.height / MODEL_DIMENSIONS.CARTON[2],
              b.depth / MODEL_DIMENSIONS.CARTON[1],
            ]}
            position={transformPos(
              pos.x + b.x * item.width,
              pos.y + b.y * item.height,
              pos.z + b.z * item.depth,
            )}
            rotation={[0, transformRot(ROTATE[type]), 0]}
          />
        ))
      }
      <StorageComponent
        scale={transformScale(
          item.width / MODEL_DIMENSIONS[type][1],
          item.height / MODEL_DIMENSIONS[type][2],
          item.depth / MODEL_DIMENSIONS[type][0],
        )}
        position={transformPos(
          pos.x + item.width / 2,
          pos.y,
          pos.z + item.depth /2,
        )}
        rotation={[0, transformRot(ROTATE[type]) + item.rotation, 0]}
      />
    </>
  }

  return null;
};

export default StorageItem;
