/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

const FoldingContainer2 = (props) => {
  const { nodes, materials } = useGLTF("/new_models/folding_container_2.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.folding_container.geometry}
        material={materials["Material.005"]}
      />
    </group>
  );
}

useGLTF.preload("/new_models/folding_container_2.glb");

export default FoldingContainer2;
