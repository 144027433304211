import React, { useState } from 'react'
import { map as lMap, sortBy as lSort } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'

import { CircularProgress } from '@mui/material'

import H2Title from '../atoms/H2Title'
import ModelRow from '../molecules/ModelRow'
import UploadModule from '../molecules/UploadModule'

const ExecuteListTable = (props) => {
  const { t } = useTranslation()
  const [isInProgress, setIsInProgress] = useState(false)
  const {
    titleI18nKey,
    kind,
    list,
    handleFileUpload=null,
    setErrorMessage,
    role=null,
    marginBottom='',
    mode='',
  } = props

  const isComfirmation = !handleFileUpload

  const fileKind = kind === 'model' ? 'model_kind' : 'master_kind'
  const filename = kind === 'model' ? 'model_filename': 'master_filename'

  const isDisabled = role === 'viewer' || (kind === 'model' && role === 'user')

  return (
    <div style={{marginBottom: marginBottom}}>
      <H2Title
        type='subtitle'
        i18nKey={titleI18nKey}
      />
      {(!isComfirmation && !isDisabled) && (
        <UploadModule
          kind={kind}
          setIsInProgress={setIsInProgress}
          handleFileUpload={handleFileUpload}
          setErrorMessage={setErrorMessage}
          mode={mode}
        />
      )}
      <table className='asoko2-table' cellSpacing={0}>
        <thead>
          <tr key='thead'>
            <th><Trans t={t} i18nKey={fileKind} /></th>
            <th><Trans t={t} i18nKey={filename} /></th>
            <th><Trans t={t} i18nKey='updated' /></th>
            <th><Trans t={t} i18nKey='updated_user' /></th>
          </tr>
        </thead>
        <tbody style={{position: 'relative'}}>
          {lMap(lSort(list, 'first_name'), (u) => (
            <ModelRow
              u={u}
              isTransparent={isInProgress}
            />
          ))}
          {isInProgress && (
            <div>
              <CircularProgress
                style={{
                  position: 'absolute',
                  top: '10%',
                  left: '50%'
                }}
              />
            </div>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ExecuteListTable
