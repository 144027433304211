import React from "react";
import SpatialInfo from "./spatial_info";
import CargoInfo from "./cargo_info";
// import StorageMethods from "./storage_methods";
import {ARRANGEMENT_DIRECTIONS,
  // ENUM_CARGO_TYPES,
  ENUM_STORAGE_METHODS} from "../../../utils";
import {Trans, withTranslation} from "react-i18next";
import CommaInput from "../../../components/comma_input";

const InputSection = ({ inputs, updateValue, t }) => {
  const isDirectLayout = (inputs.storageMethod === ENUM_STORAGE_METHODS.direct ||
    inputs.storageMethod === ENUM_STORAGE_METHODS.pallet_rack);
  return (
    <>
      <h4 className="section-heading">
        <Trans i18nKey="Spatial information" t={t} />
      </h4>
      <SpatialInfo inputs={inputs} updateValue={updateValue} />

      <h4 className="section-heading">
        <Trans t={t} i18nKey="Cargo and storage type" />
      </h4>
      <CargoInfo inputs={inputs} updateValue={updateValue} />

      {/*<h4 className="section-heading">*/}
      {/*  <Trans t={t} i18nKey="Storage method" />*/}
      {/*</h4>*/}
      {/*<StorageMethods inputs={inputs} updateValue={updateValue} />*/}

      <h4 className="section-heading">
        <Trans t={t} i18nKey="Layout constraint inputs" />
      </h4>
      <div className="mt-4 ml-17">
        <h4 className="section-heading">
          <Trans t={t} i18nKey="Direction of arrangement" />
        </h4>
        <div className="ml-17">
          <div className="input-margin-section">
            {
              ARRANGEMENT_DIRECTIONS.map(a => (
                <div className="input-row" key={a.id}>
                  <label className="checkbox-container">
                    <Trans i18nKey={a.text} t={t} />
                    <input type="checkbox" checked={inputs.arrangementDirection === a.id} onChange={() => {
                      updateValue({
                        target: {
                          value: a.id,
                          dataset: {
                            path: "arrangementDirection"
                          }
                        }
                      })
                    }} />
                    <span className="checkbox-mark"></span>
                  </label>
                </div>
              ))
            }
          </div>
        </div>
        <h4 className="section-heading">
          <Trans t={t} i18nKey="Constraints of passageway" />
        </h4>
        <div className="input-margin-section ml-17">
          <div className="input-row">
            <div className="input-row-text">
              <Trans t={t} i18nKey={isDirectLayout ? "Passageway width" : "Main passageway width"} />
            </div>
            <div className="input-wrapper">
              <input
                className="input-field bob"
                type="number"
                step={0.01}
                value={inputs.mainPassagewayWidth}
                onChange={updateValue}
                data-path="mainPassagewayWidth"
              />
              <div className="input-unit">m</div>
            </div>
            <div className="input-wrapper" />
            <div className="input-wrapper" />
          </div>
          {
            !isDirectLayout && (
              <div className="input-row">
                <div className="input-row-text">
                  <Trans t={t} i18nKey="Sub passageway width" />
                </div>
                <div className="input-wrapper">
                  <input
                    className="input-field bob"
                    type="number"
                    step={0.01}
                    value={inputs.subPassagewayWidth}
                    onChange={updateValue}
                    data-path="subPassagewayWidth"
                  />
                  <div className="input-unit">m</div>
                </div>
                <div className="input-wrapper" />
                <div className="input-wrapper" />
              </div>
            )
          }
        </div>

        <h4 className="section-heading">
          <Trans t={t} i18nKey="Constraints of layout" />
        </h4>
        <div className="input-margin-section ml-17">
          <div className="input-row">
            <div className="input-row-text">
              <Trans t={t} i18nKey="From pillar" />
            </div>
            <div className="input-wrapper">
              <input
                className="input-field bob"
                type="number"
                step={0.01}
                value={inputs.clearancePillar}
                onChange={updateValue}
                data-path="clearancePillar"
              />
              <div className="input-unit">m</div>
            </div>
            <div className="input-wrapper" />
            <div className="input-wrapper" />
          </div>
          <div className="input-row">
            <div className="input-row-text">
              <Trans t={t} i18nKey="From firewall" />

            </div>
            <div className="input-wrapper">
              <input
                className="input-field bob"
                type="number"
                step={0.01}
                value={inputs.clearanceFirewall}
                onChange={updateValue}
                data-path="clearanceFirewall"
              />
              <div className="input-unit">m</div>
            </div>
            <div className="input-wrapper" />
            <div className="input-wrapper" />
          </div>
          <div className="input-row">
            <div className="input-row-text">
              <Trans t={t} i18nKey="From fire shutter" />
            </div>
            <div className="input-wrapper">
              <input
                className="input-field bob"
                type="number"
                step={0.01}
                value={inputs.clearanceFireshutter}
                onChange={updateValue}
                data-path="clearanceFireshutter"
              />
              <div className="input-unit">m</div>
            </div>
            <div className="input-wrapper" />
            <div className="input-wrapper" />
          </div>
          <div className="input-row">
            <div className="input-row-text">
              <Trans t={t} i18nKey="From beam" />
            </div>
            <div className="input-wrapper">
              <input
                className="input-field bob"
                type="number"
                step={0.01}
                value={inputs.clearanceBeam}
                onChange={updateValue}
                data-path="clearanceBeam"
              />
              <div className="input-unit">m</div>
            </div>
            <div className="input-wrapper" />
            <div className="input-wrapper" />
          </div>
        </div>


        <div className="input-margin-section">
          <div className="input-row">
            <div className="input-row-text">
              <h4 className="section-heading">
                <Trans t={t} i18nKey="Monthly rent per unit" />
              </h4>
            </div>
            <div className="input-wrapper">
              <CommaInput
                styleClasses="bob"
                value={inputs.monthlyRentPerUnit}
                onChange={updateValue}
                path="monthlyRentPerUnit"
              />
              <div className="input-unit rent">
                <Trans t={t} i18nKey="JPY/Tsubo" />
              </div>
            </div>
            <div className="input-wrapper" />
            <div className="input-wrapper" />
          </div>
        </div>
      </div>

    </>
  );
};

export default withTranslation()(InputSection);
