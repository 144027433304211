import React from 'react'

import ServiceMenuTemplate from '../../../../templates/ServiceMenuTemplate'

const SimulationMenu = () => {
  const props = {
    pageTitle: 'opt_scenario_verif',
    upperNavLinkTitle: 'newexec',
    upperNavTo: '/simulation/newexec',
    lowerNavLinkTitle: 'reshis',
    lowerNavTo: '/simulation/reshis',
    backTo: '/asoko2menu'
  }

  return <ServiceMenuTemplate props={props} />
}

export default SimulationMenu
