import React from 'react'

import InputForm from '../molecules/InputForm'

const FullNameInputForms = (props) => {
  const { firstName, setFirstName, lastName, setLastName, lang } = props

  return (
    <div className="flex">
      <div className="input-wrapper half-width mr-4">
        <InputForm
          i18nKey={lang === 'ja' ? 'last_name': 'first_name'}
          required={true}
          type="text"
          value={lang === 'ja' ? lastName : firstName}
          setValue={lang === 'ja' ? setLastName : setFirstName}
          autoComplete={`register ${lang === 'ja' ? 'last_name': 'first_name'}`}
        />
      </div>
      <div className="input-wrapper half-width ml-4">
        <InputForm
          i18nKey={lang === 'ja' ? 'first_name': 'last_name'}
          type="text"
          value={lang === 'ja' ? firstName : lastName}
          setValue={lang === 'ja' ? setFirstName : setLastName}
          autoComplete={`register ${lang === 'ja' ? 'first_name': 'last_name'}`}
        />
      </div>
    </div>
  )
}

export default FullNameInputForms
