import * as React from "react";
import {Trans, withTranslation} from "react-i18next";

const SettingsModal = ({ setSettingsModalOpen, t }) => {
  return (
    <div className="modal-wrapper" onClick={() => setSettingsModalOpen(false)}>
      <div className="modal-contents" onClick={e => { e.stopPropagation(); }}>
        <h3>
          <Trans t={t} i18nKey="Settings" />
        </h3>
      </div>
    </div>
  );
};

export default withTranslation()(SettingsModal);
