import React, { useEffect, useState } from 'react'
import { LANGUAGES } from '../../utils/constants'
import i18n from 'i18next'
import classNames from 'classnames'
import Cookies from 'js-cookie'

const localStorageLanguage = Cookies.get('lang')

const LanguageSwitcher = ({ small }) => {
  const [language, setLanguage] = useState(!!localStorageLanguage ? localStorageLanguage : LANGUAGES[0].key)

  useEffect(() => {
    Cookies.set('lang', language)
    i18n.changeLanguage(language)
  }, [language])

  const toggleLanguage = () => {
    // console.log('Toggled')
    const newLanguage = language === LANGUAGES[0].key ? LANGUAGES[1].key : LANGUAGES[0].key
    Cookies.set('lang', newLanguage)
    setLanguage(newLanguage)
  }

  return (
    <div className='lg-switch align-center'>
      <div
        className={classNames('input toggle-input', {
          small,
        })}
        onClick={toggleLanguage}>
        {LANGUAGES.map((l) => (
          <div
            className={classNames('toggle-item', {
              selected: l.key === language,
              small,
            })}
            key={l.key}>
            {l.text}
          </div>
        ))}
      </div>
    </div>
  )
}

export default LanguageSwitcher
