import React, { useContext } from 'react'

import { AuthContext } from '../../../../app'
import {
  ADD_COMPANY_BUTTON_LABEL_I18NKEY,
  COMPANY_THEAD,
  COMPANY_SORT
} from './constants'
import {
  apiGetAllCompanies,
  apiGetCompanyFromId,
  apiDeleteCompany
} from '../../../../utils/api'

import EditCompanyModal from './EditCompanyModal'
import ManageListTemplate from '../../../templates/ManageListTemplate'

const CompanyPage = () => {
  const { userDetails } = useContext(AuthContext)
  const companyId = userDetails['company_id']
  const userRole = userDetails['role']
  const canEdit = (userRole === 'admin' || userRole === 'manager')
  const canDelete = userRole === 'admin'
  const isOnlySameCompany = userRole !== 'admin'

  const getAllCompaniesOrInSameCompanyApi = () => {
    if (isOnlySameCompany) {
      return apiGetCompanyFromId(companyId)
    } else {
      return apiGetAllCompanies()
    }
  }

  const props = {
    fetchDataApi: getAllCompaniesOrInSameCompanyApi,
    deleteDataApi: apiDeleteCompany,
    ADD_BUTTON_LABEL_I18NKEY: ADD_COMPANY_BUTTON_LABEL_I18NKEY,
    CONST_THEAD: COMPANY_THEAD.map(head => head),
    CONST_SORT: COMPANY_SORT,
    canEdit: canEdit,
    canDelete: canDelete,
    EditModal: EditCompanyModal,
  }

  return <ManageListTemplate props={props} />
}

export default CompanyPage
