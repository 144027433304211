export const ADD_COMPANY_BUTTON_LABEL_I18NKEY = 'create_company'

export const COMPANY_THEAD = [
  'company_name',
  'company_short_name',
  'edit',
  'delete',
]

export const COMPANY_SORT = 'companyName'

export const ERROR_MESSAGE_OF_DUPLICATE_COMPANY_CODE = 'ERROR_duplicate key value violates unique constraint "company_short_name_key"'
