import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const FileUploader = (props) => {
  const { t } = useTranslation()
  const {
    kind,
    image,
    handleUploaderClicked,
    handleImageChange,
    isUploadDone=false
  } = props

  return (
    <label
      style={{
        display: 'inline-block',
        padding: '4px',
        margin: '5px 0',
        minWidth: '300px',
        borderRadius: '4px',
        border: '1px solid #696969',
      }}
    >
      <input
        type='file'
        accept={(kind==='model') ? '.spp': '.xlsx,.csv'}
        onClick={handleUploaderClicked}
        onChange={handleImageChange}
      />
      {!image
        ? <Trans t={t} i18nKey={'select_file_for_upload'} />
        : isUploadDone
          ? <Trans t={t} i18nKey={'message_upload_done'} />
          : image['name']
      }
    </label>
  )
}

export default FileUploader
