import React from 'react'

import ServiceMenuTemplate from '../../../templates/ServiceMenuTemplate'

const Asoko2Menu = () => {
  const props = {
    pageTitle: 'asoko2',
    upperNavLinkTitle: 'opt_picking_list',
    upperNavTo: '/pickingmenu',
    lowerNavLinkTitle: 'opt_scenario_verif',
    lowerNavTo: '/simulationmenu',
    backTo: '/home'
  }

  return <ServiceMenuTemplate props={props} />
}

export default Asoko2Menu
