import React from "react";
import {isInteger} from "lodash";


const CommaInput = ({
  value, onChange, path, styleClasses
}) => {
  return (
    <input
      className={`input-field ${styleClasses}`}
      type="text"
      value={value}
      onChange={onChange}
      onBlur={(e) => {
        let newValue = e.target.value;
        if(isInteger(value)) {
          newValue = value.toLocaleString("en-US");
        }
        onChange({
          target: {
            dataset: {
              path,
            },
            value: newValue,
          }
        });
      }}
      data-path={path}
    />
  )
};

export default CommaInput;
