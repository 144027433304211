import React from "react";
import {WALL_TYPES, WALLS_FOR_SECTION_COUNT} from "../../../../utils";

const Walls = ({ inputs }) => {
  const {
    ceilingHeight,
    fireCompartmentWidthSections,
    fireCompartmentDepthSections,
    pillarSpanDepth,
    pillarSpanWidth,
    spandrelWallThickness,
  } = inputs;
  const widthWalls = WALLS_FOR_SECTION_COUNT[fireCompartmentWidthSections];
  const depthWalls = WALLS_FOR_SECTION_COUNT[fireCompartmentDepthSections];

  return (
    <>
      {/* Walls */}
      {
        widthWalls.map((w, index) => {
          if(w === WALL_TYPES.SHUTTER) {
            return null;
          }
          return (
            <React.Fragment key={`${w}-${index}`}>
              <mesh position={[index * pillarSpanWidth + pillarSpanWidth / 2, ceilingHeight / 2, 0]}>
                <boxGeometry args={[pillarSpanWidth, ceilingHeight - 0.01, spandrelWallThickness]} />
                <meshStandardMaterial color={"#777777"} />
              </mesh>
              <mesh position={[index * pillarSpanWidth + pillarSpanWidth / 2, ceilingHeight / 2, pillarSpanDepth * fireCompartmentDepthSections]}>
                <boxGeometry args={[pillarSpanWidth, ceilingHeight - 0.01, spandrelWallThickness]} />
                <meshStandardMaterial color={"#777777"} />
              </mesh>
            </React.Fragment>
          );
        })
      }
      {
        depthWalls.map((w, index) => {
          if(w === WALL_TYPES.SHUTTER) {
            return null;
          }
          return (
            <React.Fragment key={`${w}-${index}`}>
              <mesh position={[0, ceilingHeight / 2, index * pillarSpanDepth + pillarSpanDepth / 2]}>
                <boxGeometry args={[spandrelWallThickness, ceilingHeight - 0.01, pillarSpanDepth]} />
                <meshStandardMaterial color={"#777777"} />
              </mesh>
              <mesh position={[pillarSpanWidth * fireCompartmentWidthSections, ceilingHeight / 2, index * pillarSpanDepth + pillarSpanDepth / 2]}>
                <boxGeometry args={[spandrelWallThickness, ceilingHeight - 0.01, pillarSpanDepth]} />
                <meshStandardMaterial color={"#777777"} />
              </mesh>
            </React.Fragment>
          );
        })
      }

      </>
  );
}

export default Walls;