import React from "react";
import _ from "lodash";
import {Trans, withTranslation} from "react-i18next";
import DecimalInput from "../../../components/decimal_input";
import { ENUM_STORAGE_METHODS} from "../../../utils";

const StorageSpecs = ({
  storage,
  inputs,
  t,
  updateValue,
  canEnterCargoSpecs,
}) => {
  if(storage.options.length > 0) {
    return <div className="cargo-info-collapsed-content">
      {
        storage.options.map(co => {
          const widthKey = inputs.storageInfoCollapsed[co].width;
          const depthKey = inputs.storageInfoCollapsed[co].depth;
          const heightKey = inputs.storageInfoCollapsed[co].height;
          const levelsKey = inputs.storageInfoCollapsed[co].levels;
          const rowsKey = inputs.storageInfoCollapsed[co].rows;
          return (
            <div className="input-row" key={co}>
              <div className="input-row-text small">
                <Trans t={t} i18nKey={co} />
              </div>
              {
                storage.text !== ENUM_STORAGE_METHODS.medium_duty_rack && (
                  <div className="input-wrapper">
                    <div className="input-label">
                      <Trans t={t} i18nKey="Width" />
                    </div>
                    {
                      canEnterCargoSpecs ? (
                        <div>
                          { inputs.storageSpecs[widthKey] } m
                        </div>
                      ) : (
                        <>
                          <DecimalInput
                            styleClasses="bob"
                            value={inputs.storageSpecs[widthKey]}
                            onChange={updateValue}
                            path={`storageSpecs[${widthKey}]`}
                          />
                          <div className="input-unit">m</div>
                        </>
                      )
                    }
                  </div>
                )
              }
              <div className="input-wrapper">
                <div className="input-label">
                  <Trans t={t} i18nKey="Depth" />
                </div>
                {
                  canEnterCargoSpecs ? (
                    <div>
                      { inputs.storageSpecs[depthKey] } m
                    </div>
                  ) : (
                    <>
                      <DecimalInput
                        styleClasses="bob"
                        value={inputs.storageSpecs[depthKey]}
                        onChange={updateValue}
                        path={`storageSpecs[${depthKey}]`}
                      />
                      <div className="input-unit">m</div>
                    </>
                  )
                }
              </div>
              <div className="input-wrapper">
                <div className="input-label">
                  <Trans t={t} i18nKey="Height" />
                </div>
                {
                  canEnterCargoSpecs ? (
                    <div>
                      {inputs.storageSpecs[heightKey]} m
                    </div>
                  ) : (
                    <>
                      <DecimalInput
                        styleClasses="bob"
                        value={inputs.storageSpecs[heightKey]}
                        onChange={updateValue}
                        path={`storageSpecs[${heightKey}]`}
                      />
                      <div className="input-unit">m</div>
                    </>
                  )
                }
              </div>
              {
                !_.includes([
                  ENUM_STORAGE_METHODS.medium_duty_rack,
                  ENUM_STORAGE_METHODS.folding_containers,
                  ENUM_STORAGE_METHODS.carton_cases
                ], storage.text) && (
                  <div className="input-wrapper">
                    <div className="input-label">
                      <Trans t={t} i18nKey="Weight" />
                    </div>
                    {
                      canEnterCargoSpecs ? (
                        <div>
                          {inputs.storageInfoCollapsed[co].weight} kg
                        </div>
                      ) : (
                        <>
                          <DecimalInput
                            styleClasses="bob"
                            value={inputs.storageInfoCollapsed[co].weight}
                            onChange={updateValue}
                            path={`storageInfoCollapsed[${co}].weight`}
                          />
                          <div className="input-unit kg">kg</div>
                        </>
                      )
                    }
                  </div>
                )
              }
              {
                storage.text === ENUM_STORAGE_METHODS.medium_duty_rack && (
                  <div className="input-wrapper">
                    <div className="input-label">
                      <Trans t={t} i18nKey="Rows in rack" />
                    </div>
                    <input
                      className="input-field bob"
                      type="number"
                      value={inputs.storageSpecs[rowsKey]}
                      onChange={updateValue}
                      data-path={`storageSpecs[${rowsKey}]`}
                    />
                  </div>
                )
              }
              {
                _.includes([
                  ENUM_STORAGE_METHODS.medium_duty_rack,
                  ENUM_STORAGE_METHODS.folding_containers,
                  ENUM_STORAGE_METHODS.carton_cases
                ], storage.text) && (
                  <div className="input-wrapper">
                    <div className="input-label">
                      <Trans t={t} i18nKey="Levels" />
                    </div>
                    <input
                      className="input-field bob"
                      value={inputs.storageSpecs[levelsKey]}
                      onChange={updateValue}
                      data-path={`storageSpecs[${levelsKey}]`}
                    />
                  </div>
                )
              }
            </div>
          )
        })
      }
    </div>;
  }
  return null;
};

export default withTranslation()(StorageSpecs);
