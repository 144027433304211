import React from 'react'
import Cookies from 'js-cookie'
import { Trans, withTranslation } from 'react-i18next'

const ResultRow = ({ u, key }) => {

  const link1 = <a href={u[6]}>▼Download</a>
  const linkForCsv = <a href={u[8]}>▼Download(csv)</a>
  // todo: バックエンド対応後、linkForExcelを整理する
  const linkForExcel = <a href={u[7]}>▼Download(Excel)</a>
  const linkWorkload = <a href={u[9]}>▼Download</a>
  const err_code = u[1] === 'E_00_00_00' ? '' : '(' + u[1] + ')'
  const lang = Cookies.get('lang') || 'ja'
  const userFirstName = !!u[10] ? u[10] : ''
  const userLastName = !!u[11] ? u[11] : ''
  const updatedUserFullName =
    lang === 'ja'
      ? (`${userLastName} ${userFirstName}`)
      : (`${userFirstName} ${userLastName}`)


  return (
    <tr key={key}>
      <td>{u[0]}</td>
      <td>{u[5]}</td>
      <td><Trans i18nKey={u[1]}/>{err_code}</td>
      <td>{u[6]!=='' ? link1 : '-'}</td>
      <td>{u[8]!=='' ? linkForCsv : ''} {u[7]!=='' ? linkForExcel : ''}</td>
      <td>{u[9]!=='' ? linkWorkload : '-'}</td>
      <td style={{width: '150px'}}>{updatedUserFullName}</td>
    </tr>
  )
}

export default withTranslation()(ResultRow)
