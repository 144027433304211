import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CircularProgress } from '@mui/material'

import ModalButtonsSet from '../molecules/ModalButtonsSet'
import ExecuteListTable from './ExecuteListTable'

const ExecuteModal = (props) => {
  const { t } = useTranslation()
  const { setIsModalOpen, isInProgress, onExec, modelList, masterList } = props

  return (
    <div className='modal-wrapper' onClick={() => setIsModalOpen(false)} >
      <div
        style={{width: '60%'}}
        className='modal-contents'
        onClick={e => { e.stopPropagation() }}
      >
        <span><Trans t={t} i18nKey='exec_message' /></span>

        <div style={{position: 'relative'}}>
          <div style={{opacity: isInProgress ? '.5' : '1'}}>
            <ExecuteListTable
              titleI18nKey='simulation_model_files'
              kind='model'
              list={modelList}
            />
            <ExecuteListTable
              titleI18nKey='master_files'
              kind='master'
              list={masterList}
            />
          </div>
          {isInProgress && (
            <CircularProgress
              style={{
                position: 'absolute',
                top: '60%',
                left: '50%'
              }}
            />
          )}
        </div>

        <div className='flex align-center mt-4 mb-4'>
          <ModalButtonsSet
            confirmButtonI18nKey='execute'
            submitHandler={() => onExec()}
            cancelHandler={() => setIsModalOpen(false)}
          />
        </div>
      </div>
    </div>
  )
}

export default ExecuteModal
