import React, { useState } from 'react'
import { Trans, withTranslation, useTranslation } from 'react-i18next'

import { List, ListItemButton, Collapse } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import LeftMenuItem from '../molecules/LeftMenuItem'
import Icon from '../atoms/Icon'

const NestedMenu = (props) => {
  const { t } = useTranslation()
  const { parentMenuItemObject, childrenMenuItemList } = props
  const { parentMenuIcon, parentMenuI18nKey } = parentMenuItemObject

  const [isNestOpen, setIsNestOpen] = useState(true)

  const toggleIsNestOpen = () => {
    setIsNestOpen(!isNestOpen)
  }

  return (
    <div style={{position: 'relative'}}>
      <ListItemButton onClick={toggleIsNestOpen} style={{ paddingLeft: 12 }}>
        <Icon iconName={parentMenuIcon} />
        <Trans t={t} i18nKey={parentMenuI18nKey} />
        {isNestOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isNestOpen}>
        <List component='div' disablePadding>
          {childrenMenuItemList.map((item) => {
            return (
              <LeftMenuItem
                key={item.i18nKey}
                isChildren
                to={item.to}
                iconName={item.icon}
                i18nKey={item.i18nKey}
              />
            )
          })}
        </List>
      </Collapse>
    </div>
  )
}

export default withTranslation()(NestedMenu)