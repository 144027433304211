import React, { useContext } from 'react'

import { AuthContext } from '../../../../app'

import {
  ADD_WAREHOUSE_BUTTON_LABEL_I18NKEY,
  WAREHOUSE_THEAD,
  WAREHOUSE_SORT
} from './constants'
import {
  apiGetAllWarehouses,
  apiGetWarehouseFromCompanyId,
  apiDeleteWarehouse
} from '../../../../utils/api'

import EditWarehouseModal from './EditWarehouseModal'
import ManageListTemplate from '../../../templates/ManageListTemplate'

const WareHousePage = () => {
  const { userDetails } = useContext(AuthContext)
  const companyId = userDetails['company_id']
    const userRole = userDetails['role']

  const canEdit = (userRole === 'admin' || userRole === 'manager')
  const canDelete = (userRole === 'admin' || userRole === 'manager')
  const isOnlySameCompany = userRole !== 'admin'

  const getAllUserOrInSameCompanyApi = () => {
    if (isOnlySameCompany) {
      return apiGetWarehouseFromCompanyId(companyId)
    } else {
      return apiGetAllWarehouses()
    }
  }

  const props = {
    fetchDataApi: getAllUserOrInSameCompanyApi,
    deleteDataApi: apiDeleteWarehouse,
    ADD_BUTTON_LABEL_I18NKEY: ADD_WAREHOUSE_BUTTON_LABEL_I18NKEY,
    CONST_THEAD: WAREHOUSE_THEAD.map(head => head),
    CONST_SORT: WAREHOUSE_SORT,
    canEdit: canEdit,
    canDelete: canDelete,
    EditModal: EditWarehouseModal,
  }

  return <ManageListTemplate props={props} />
}

export default WareHousePage
