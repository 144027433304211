import React from 'react'
import Cookies from 'js-cookie'

import Icon from '../atoms/Icon'
import { ROLE_TEXTS } from '../../utils/constants'
import { Trans, useTranslation } from 'react-i18next'

const ListTableRow = (props) => {
  const { t } = useTranslation()
  const { openEditModal, openDeleteModal, columnObject, canEdit, canDelete } = props

  const lang = Cookies.get('lang') || 'ja'
  const displayColList = [
    'first_name', 'email', 'role',
    'name', 'short_name', 'c_name'
  ]

  return (
    <tr>
      {Object.keys(columnObject).map(columnKey => {
        if (columnKey === 'first_name') {
          return (
            <td key={columnObject['id'] + columnKey}>
              <div className='flex' style={{alignItems: 'center'}}>
                <Icon iconName='person' />
                {lang === 'ja'
                  ? <div>{columnObject['last_name']} {columnObject['first_name']}</div>
                  : <div>{columnObject['first_name']} {columnObject['last_name']}</div>
                }
              </div>
            </td>
          )
        } else if (columnKey === 'role') {
          return (
            <td key={columnObject['id'] + columnKey}>
              <Trans t={t} i18nKey={ROLE_TEXTS[columnObject['role']]} />
            </td>
          )
        } else if (displayColList.includes(columnKey)) {
          return (
            <td key={columnObject['id'] + columnKey}>
              {columnObject[columnKey]}
            </td>
          )
        } else {
          return null
        }
      })}
      <td>
        {canEdit && (
          <button className='btn-s' onClick={() => openEditModal(columnObject['id'])}>
            <span className='material-icons'>edit</span>
          </button>
        )}
      </td>
      <td>
        {canDelete && (
          <button className='btn-s danger' onClick={() => openDeleteModal(columnObject['id'])}>
            <span className='material-icons'>delete_outline</span>
          </button>
        )}
      </td>
    </tr>
  )
}

export default ListTableRow
