import React  from "react";
import { useGLTF } from "@react-three/drei";

export function RollboxPallet(props) {
  const { nodes, materials } = useGLTF("/new_models/roll_box_pallet.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.roll_box_pallet_2.geometry}
        material={materials["Material.003"]}
      />
    </group>
  );
}

useGLTF.preload("/new_models/roll_box_pallet.glb");

export default RollboxPallet;
