import React from "react";
import {Trans, withTranslation} from "react-i18next";
import DecimalInput from "../../../components/decimal_input";
import Collapsible from "../../../components/collapsible";

const SpatialInfo = ({ inputs, updateValue, t }) => {
  return (
    <div className="input-margin-section">
      <div className="input-row">
        <div className="input-row-text">
          <Trans t={t} i18nKey="Pillar Span + Ceiling Height" />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans i18nKey="Width" t={t} />
          </div>
          <DecimalInput
            styleClasses="bob bgw"
            value={inputs.pillarSpanWidth}
            onChange={updateValue}
            path="pillarSpanWidth"
          />
          <div className="input-unit">m</div>
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Depth" />
          </div>
          <DecimalInput
            styleClasses="bob bgw"
            value={inputs.pillarSpanDepth}
            onChange={updateValue}
            path="pillarSpanDepth"
          />
          <div className="input-unit">m</div>
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Height" />
          </div>
          <DecimalInput
            styleClasses="bob bgw"
            value={inputs.ceilingHeight}
            onChange={updateValue}
            path="ceilingHeight"
          />
          <div className="input-unit">m</div>
        </div>
      </div>

      <div className="input-row">
        <div className="input-row-text">
          <Trans t={t} i18nKey="Floor load capacity" />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            styleClasses="bob bgw"
            value={inputs.floorLoadCapacity}
            onChange={updateValue}
            path="floorLoadCapacity"
          />
          <div className="input-unit tm2">t/m<sup>2</sup></div>
        </div>
        <div className="input-wrapper" />
        <div className="input-wrapper" />
      </div>

      <div className="input-row">
        <div className="input-row-text">
          <Trans t={t} i18nKey="Fire compartment" />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Width sections" />
          </div>
          <input
            className="input-field bob bgw"
            type="number"
            step={1}
            min={2}
            max={6}
            value={inputs.fireCompartmentWidthSections}
            onChange={updateValue}
            data-path="fireCompartmentWidthSections"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Depth sections" />
          </div>
          <input
            className="input-field bob bgw"
            type="number"
            step={1}
            min={2}
            max={6}
            value={inputs.fireCompartmentDepthSections}
            onChange={updateValue}
            data-path="fireCompartmentDepthSections"
          />
        </div>
        <div className="input-wrapper" />
      </div>
      <Collapsible heading="Pillar and wall size">
        <div className="collapsible-content">
          <div className="input-row">
            <div className="input-row-text">
              <Trans t={t} i18nKey="Pillar size" />
            </div>
            <div className="input-wrapper">
              <div className="input-label">
                <Trans t={t} i18nKey="Width" />
              </div>
              <DecimalInput
                styleClasses="bob bgg"
                value={inputs.pillarSizeWidth}
                onChange={updateValue}
                path="pillarSizeWidth"
              />
              <div className="input-unit">m</div>
            </div>
            <div className="input-wrapper">
              <div className="input-label">
                <Trans t={t} i18nKey="Depth" />
              </div>
              <DecimalInput
                styleClasses="bob bgg"
                value={inputs.pillarSizeDepth}
                onChange={updateValue}
                path="pillarSizeDepth"
              />
              <div className="input-unit">m</div>
            </div>
            <div className="input-wrapper" />
          </div>
          <div className="input-row">
            <div className="input-row-text">
              <Trans t={t} i18nKey="Thickness of spandrel wall" />
            </div>
            <div className="input-wrapper">
              <div className="input-label">
                <Trans t={t} i18nKey="Thickness" />
              </div>
              <DecimalInput
                styleClasses="bob bgg"
                value={inputs.spandrelWallThickness}
                onChange={updateValue}
                path="spandrelWallThickness"
              />
              <div className="input-unit">m</div>
            </div>
            <div className="input-wrapper" />
            <div className="input-wrapper" />
          </div>
        </div>
      </Collapsible>
    </div>
  );
}

export default withTranslation()(SpatialInfo);