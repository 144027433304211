import _ from "lodash";

//export const BACKEND_HOST = window.location.hostname === "localhost" ? "http://localhost:3001" :'https://backend.a-soko.net';
export const BACKEND_HOST = window.location.hostname === "localhost" ? "http://localhost:3001" : (window.location.hostname === "a-soko.net"? 'https://backend.a-soko.net' :'https://backend-dev.a-soko.net');
// export const BACKEND_HOST = 'https://backend-dev.a-soko.net';

export const VIZ_TYPES = {
  "2d": "2D",
  "3d": "3D",
};

export const NON_EMPTY_INPUTS = {
  fireCompartmentWidthSections: "Fire compartment sections",
  fireCompartmentDepthSections: "Fire compartment sections",
  pillarSpanWidth: "Pillar span",
  pillarSpanDepth: "Pillar span",
  ceilingHeight: "Ceiling height",
  floorLoadCapacity: "Floor load capacity",
  pillarSizeWidth: "Pillar size",
  pillarSizeDepth: "Pillar size",
  spandrelWallThickness: "Wall thickness",
  "cargoSpecs.width": "Cargo dimensions",
  "cargoSpecs.depth": "Cargo dimensions",
  "cargoSpecs.height": "Cargo dimensions",
  "cargoSpecs.weight": "Cargo weight",

};

export const ENUM_CARGO_VIZ_TYPES = {
  PALLET: "PALLET",
  ROLLBOX_PALLET_1: "ROLLBOX_PALLET_1",
  ROLLBOX_PALLET_2: "ROLLBOX_PALLET_2",
  NESTING_RACK_1P_1S: "NESTING_RACK_1P_1S",
  NESTING_RACK_1P_2S: "NESTING_RACK_1P_2S",
  NESTING_RACK_2P_1S: "NESTING_RACK_2P_1S",
  NESTING_RACK_2P_2S: "NESTING_RACK_2P_2S",
  FOLDING_CONTAINER_1S: "FOLDING_CONTAINER_1S",
  FOLDING_CONTAINER_2S: "FOLDING_CONTAINER_2S",
  CARTON_BOX_1S: "CARTON_BOX_1S",
  CARTON_BOX_2S: "CARTON_BOX_2S",
  PALLET_RACK_1P_1S: "PALLET_RACK_1P_1S",
  PALLET_RACK_2P_1S: "PALLET_RACK_2P_1S",
  PALLET_RACK_1P_2S: "PALLET_RACK_1P_2S",
  PALLET_RACK_2P_2S: "PALLET_RACK_2P_2S",
  MDR_1W_1S: "MDR_1W_1S",
  MDR_2W_1S: "MDR_2W_1S",
  MDR_1W_2S: "MDR_1W_2S",
  MDR_2W_2S: "MDR_2W_2S",
};

export const getQueryStringParam = (sParam) => {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');

  for (let i = 0; i < sURLVariables.length; i++) {
    const sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1];
    }
  }
};

export const removeQueryStringParam = (paramName) => {
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.delete(paramName);
  window.history.replaceState({}, document.title, newUrl.href);
}

export const LOGIN_STATES = {
  INITIAL: 'INITIAL',
  PROGRESS: 'PROGRESS',
  NO_TOKEN: 'NO_TOKEN',
  INVALID_TOKEN: 'INVALID_TOKEN',
  LOGGED_IN: 'LOGGED_IN',
  ERROR: 'ERROR',
};

export const WALL_TYPES = {
  WALL: "WALL",
  SHUTTER: "SHUTTER",
};

export const WALLS_FOR_SECTION_COUNT = {
  "2": [
    WALL_TYPES.WALL,
    WALL_TYPES.SHUTTER,
  ],
  "3": [
    WALL_TYPES.WALL,
    WALL_TYPES.SHUTTER,
    WALL_TYPES.WALL,
  ],
  "4": [
    WALL_TYPES.WALL,
    WALL_TYPES.SHUTTER,
    WALL_TYPES.SHUTTER,
    WALL_TYPES.WALL,
  ],
  "5": [
    WALL_TYPES.WALL,
    WALL_TYPES.WALL,
    WALL_TYPES.SHUTTER,
    WALL_TYPES.WALL,
    WALL_TYPES.WALL,
  ],
  "6": [
    WALL_TYPES.WALL,
    WALL_TYPES.WALL,
    WALL_TYPES.SHUTTER,
    WALL_TYPES.SHUTTER,
    WALL_TYPES.WALL,
    WALL_TYPES.WALL,
  ],
}

export const IS_DEV = process.env.NODE_ENV === 'development';

export const ENUM_CARGO_TYPES = {
  pallet: "Pallet",
  rollbox_pallet: "Rollbox pallet",
  folding_container: "Folding container",
  piece: "Piece",
};

export const ENUM_STORAGE_METHODS = {
  direct: "Direct storage",
  pallet_rack: "Pallet rack",
  nesting_rack: "Nesting rack",
  medium_duty_rack: "Medium duty rack",
  folding_containers: "Folding containers",
  carton_cases: "Carton Cases",
};

export const ENUM_CARGO_OPTIONS = {
  pallet_jis: "JIS standard",
  pallet_beverage: "Beverage",
  pallet_eu: "EU standard",
  pallet_us: "US standard",
  pallet_cold: "Cold storage",
  rollbox_1: "Roll box pallet 1",
  rollbox_2: "Roll box pallet 2",
  folding_container: "Folding container",
  carton_box: "Carton box",
  medium_duty_rack: "Medium duty rack"
};

export const ENUM_STORAGE_OPTIONS = {
  pallet_rack_2p_1s: "pallet_rack_2p_1s",
  pallet_rack_1p_1s: "pallet_rack_1p_1s",
  pallet_rack_2p_2s: "pallet_rack_2p_2s",
  pallet_rack_1p_2s: "pallet_rack_1p_2s",
  nesting_rack_1p_1s: "nesting_rack_1p_1s",
  nesting_rack_2p_2s: "nesting_rack_2p_2s",
  mdr_2w_1s: "mdr_2w_1s",
  mdr_2w_2s: "mdr_2w_2s",
  mdr_1w_1s: "mdr_1w_1s",
  mdr_1w_2s: "mdr_1w_2s",
  fc_1c_1s: "fc_1c_1s",
  fc_1c_2s: "fc_1c_2s",
  // carton_boxes: "carton_boxes",
};

export const ENUM_LAYOUT_COLUMN_TYPES = {
  PILLAR: "PILLAR",
  WALL: "WALL",
  SHUTTER: "SHUTTER",
};

export const ENUM_STORAGE_SIZE_TYPES = {
  DOUBLE: "DOUBLE",
  SINGLE: "SINGLE",
};

export const CARGO_TYPES = [
  {
    text: ENUM_CARGO_TYPES.pallet,
    optionInputKey: "palletOption",
    options: [
      ENUM_CARGO_OPTIONS.pallet_jis,
      ENUM_CARGO_OPTIONS.pallet_beverage,
      ENUM_CARGO_OPTIONS.pallet_eu,
      ENUM_CARGO_OPTIONS.pallet_us,
      ENUM_CARGO_OPTIONS.pallet_cold,
    ],
  },
  {
    text: ENUM_CARGO_TYPES.rollbox_pallet,
    optionInputKey: "rollboxOption",
    options: [
      ENUM_CARGO_OPTIONS.rollbox_1,
      ENUM_CARGO_OPTIONS.rollbox_2,
    ],
  },
  {
    text: ENUM_CARGO_TYPES.piece,
    optionInputKey: "foldingOption",
    options: [
      // ENUM_CARGO_OPTIONS.medium_duty_rack,
      // ENUM_CARGO_OPTIONS.folding_container,
      // ENUM_CARGO_OPTIONS.carton_box,
    ],
  },
];

export const ARRANGEMENT_DIRECTIONS = [
  {
    id: "vertical",
    text: "Vertical Direction toward Truck Berth",
  },
  {
    id: "horizontal",
    text: "Horizontal Direction toward Truck Berth",
  }
]

export const STORAGE_METHODS = [
  {
    text: ENUM_STORAGE_METHODS.direct,
    cargo_types: [CARGO_TYPES[0].text, CARGO_TYPES[1].text],
    options: [],
  },
  {
    text: ENUM_STORAGE_METHODS.pallet_rack,
    cargo_types: [CARGO_TYPES[0].text],
    options: [
      ENUM_STORAGE_OPTIONS.pallet_rack_2p_1s,
      ENUM_STORAGE_OPTIONS.pallet_rack_1p_1s,
      ENUM_STORAGE_OPTIONS.pallet_rack_2p_2s,
      ENUM_STORAGE_OPTIONS.pallet_rack_1p_2s,
    ],
  },
  {
    text: ENUM_STORAGE_METHODS.nesting_rack,
    cargo_types: [CARGO_TYPES[0].text],
    options: [
      ENUM_STORAGE_OPTIONS.nesting_rack_1p_1s,
      ENUM_STORAGE_OPTIONS.nesting_rack_2p_2s,
    ],
  },
  {
    text: ENUM_STORAGE_METHODS.medium_duty_rack,
    cargo_types: [CARGO_TYPES[2].text],
    options: [
      ENUM_STORAGE_OPTIONS.mdr_2w_1s,
      ENUM_STORAGE_OPTIONS.mdr_1w_1s,
      ENUM_STORAGE_OPTIONS.mdr_2w_2s,
      ENUM_STORAGE_OPTIONS.mdr_1w_2s,
    ],
  },
  {
    text: ENUM_STORAGE_METHODS.folding_containers,
    cargo_types: [CARGO_TYPES[2].text],
    options: [
      ENUM_STORAGE_OPTIONS.fc_1c_1s,
      ENUM_STORAGE_OPTIONS.fc_1c_2s,
    ],
  },
  {
    text: ENUM_STORAGE_METHODS.carton_cases,
    cargo_types: [CARGO_TYPES[2].text],
    options: [
      ENUM_STORAGE_OPTIONS.fc_1c_1s,
      ENUM_STORAGE_OPTIONS.fc_1c_2s,
    ],
  },
];


export const textToNumberArray = (inputText) => {
  let sanitisedString = "";
  for (let i = 0; i < inputText.length; i++) {
    const char = inputText.charAt(i);
    if(char === "." || char === "," || _.isNumber(_.parseInt(char))) {
      sanitisedString += char;
    }
  }
  const numbersArray = _.map(sanitisedString.split(","), _.parseFloat);
  return _.sortBy(numbersArray);
};