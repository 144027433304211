import React from "react"
import { Trans, withTranslation, useTranslation } from 'react-i18next'

const ModalTitleLabel = (props) => {
  const { t } = useTranslation()
  const { i18nKey } = props

  return (
    <h3>
      <Trans t={t} i18nKey={i18nKey} />
    </h3>
  )
}

export default withTranslation()(ModalTitleLabel)