import React, { useState} from "react";
import _ from "lodash";

import Viz3d from "./viz-3d";
import {ARRANGEMENT_DIRECTIONS, VIZ_TYPES} from "../../../utils";
import {Trans, withTranslation} from "react-i18next";

const Visualization = ({ errors, inputs, output, newOutput, toggleFullscreen, t }) => {
  const [vizType, setVizType] = useState(VIZ_TYPES["2d"]);

  const transformPos = inputs.arrangementDirection === ARRANGEMENT_DIRECTIONS[0].id ? (x, y, z) => [x, y, z] : (x, y, z) => [z, y, x];
  const transformRot = inputs.arrangementDirection === ARRANGEMENT_DIRECTIONS[0].id ? (r) => r : (r) => (-Math.PI / 2 + r);
  const transformScale = inputs.arrangementDirection === ARRANGEMENT_DIRECTIONS[0].id ? (x, y, z) => [x, y, z] : (x, y, z) => [z, y, x];

  if(errors) {
    return (
      <div>Cannot show visualization since there are errors in the inputs</div>
    )
  }

  return <>
    <div className="flex space-between fullwidth mb-4 mt-4">
      <div>
        <div className="btn-group">
          {
            _.map(VIZ_TYPES, v => (
              <button className={`toggle-btn ${vizType === v ? "selected" : ""}`} key={v} onClick={() => setVizType(v)}>{v}</button>
            ))
          }
        </div>
      </div>
      <div>
        <button className="btn primary mr-2">
          <span className="material-icons">
            file_download
          </span> <Trans t={t} i18nKey="Download" />
        </button>
        <button className="btn primary" onClick={toggleFullscreen}>
          <span className="material-icons">
            fullscreen
          </span>
          <Trans t={t} i18nKey="Fullscreen" />
        </button>
      </div>
    </div>
    <div id="viz-wrapper">
      {
        <Viz3d
          vizType={vizType}
          inputs={inputs}
          output={output}
          newOutput={newOutput}
          transformRot={transformRot}
          transformPos={transformPos}
          transformScale={transformScale}
        />
      }
    </div>
  </>
};

export default withTranslation()(Visualization);
