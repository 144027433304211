export const ADD_WAREHOUSE_BUTTON_LABEL_I18NKEY = 'create_warehouse'

export const WAREHOUSE_THEAD = [
  'warehouse_name',
  'warehouse_short_name',
  'company_name',
  'edit',
  'delete',
]

export const WAREHOUSE_SORT = 'warehouseName'

export const ERROR_MESSAGE_OF_DUPLICATE_WAREHOUSE = 'ERROR_duplicate key value violates unique constraint "warehouse_unique"'
