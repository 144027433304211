import React, {useContext, useEffect, useState} from "react";
import {withTranslation, Trans} from "react-i18next";
import {Link} from "react-router-dom";
import axios from "axios";
import _ from "lodash";

import {BACKEND_HOST} from "../utils";
import {AuthContext} from "../app";

const HomePage = ({ t }) => {
  const [plans, setPlans ] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createName, setCreateName] = useState("");
  const { userDetails } = useContext(AuthContext);
  useEffect(() => {
    const getPlans = async () => {
      const { data: { data: plansList }} = await axios({
        method: 'GET',
        url: '/asokoplus/plans',
        baseURL: BACKEND_HOST,
        headers: {
          Authorization: `Bearer ${userDetails.token}`,
        },
      });
      setPlans(plansList);
    }
    getPlans();
  }, [userDetails]);

  const createPlan = async (e) => {
    e.preventDefault();
    const { data: { data }} = await axios({
      method: 'POST',
      url: '/asokoplus/plans',
      baseURL: BACKEND_HOST,
      headers: {
        Authorization: `Bearer ${userDetails.token}`,
      },
      data: {
        name: createName,
      }
    });
    setPlans([
      ...plans,
      data,
    ]);
    setCreateModalOpen(false);
    setCreateName("");
  };

  const deletePlan = async(planId) => {
    await axios({
      method: 'DELETE',
      url: `/asokoplus/plans/${planId}`,
      baseURL: BACKEND_HOST,
      headers: {
        Authorization: `Bearer ${userDetails.token}`,
      },
      data: {
        name: createName,
      }
    });
    setPlans(_.filter(plans, p => p.id !== planId));
  }

  return (
    <div className="padding">
      {
        createModalOpen && (
          <div className="modal-wrapper" onClick={() => setCreateModalOpen(false)}>
            <div className="modal-contents" onClick={e => { e.stopPropagation(); }}>
              <h2 className="modal-heading">Create new plan</h2>
              <form onSubmit={createPlan}>
                <label className="input-label">Plan Name</label><br />
                <input required className="input-field fw mb-4 single" type="text" value={createName} onChange={e => setCreateName(e.target.value)} />
                <div className="flex">
                  <button className="btn mr-2 primary" type="submit">Confirm</button>
                  <button className="btn" type="button" onClick={() => setCreateModalOpen(false)}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        )
      }
      <button className="btn primary mb-4" onClick={() => setCreateModalOpen(true)}>
        <Trans t={t} i18nKey="Create new plan" />
      </button>
      <div className="flex planslist-flex">
        {
          plans.map(p => (
            <Link key={p.name} className="home-plan-item" to={`/plans/${p.id}`}>
              <h3>{p.name}</h3>
              <button className="delete-plan-btn mr-4">
                <span className="material-icons">
                edit
                </span>
                Edit
              </button>
              <button onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                deletePlan(p.id);
              }} className="delete-plan-btn">
                <span className="material-icons">
                delete_outline
                </span>
                Delete</button>
            </Link>
          ))
        }
      </div>
    </div>
  );
}

export default withTranslation()(HomePage);
