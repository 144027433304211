import React from "react";
import {isInteger} from "lodash";


const DecimalInput = ({
  value, onChange = () => {}, path, styleClasses,
  readOnly
}) => {
  return (
    <input
      className={`input-field ${styleClasses}`}
      type="text"
      value={value}
      onChange={onChange || (() => {})}
      readOnly={readOnly || !onChange}
      onBlur={(e) => {
        let newValue = e.target.value;
        if(isInteger(value)) {
          newValue = `${value}.00`;
        }
        onChange({
          target: {
            dataset: {
              path,
            },
            value: newValue,
          }
        });
      }}
      data-path={path}
    />
  )
};

export default DecimalInput;
