import React from "react";
import _ from "lodash";
import {Trans, withTranslation} from "react-i18next";
import DecimalInput from "../../../components/decimal_input";
import {transformSanitizeNumbers} from "../../../utils/math";

const OutputSection = ({ inputs, t, newOutput, errors }) => {

  if(errors) {
    return _.map(errors, (e) => <div key={e}>Input for {e} is invalid</div>)
  }



  const sanitisedInputs = transformSanitizeNumbers(inputs);
  const {
    storageSpecs: {
      mdrRows1w1s,
      mdrRows2w1s,
      mdrRows1w2s,
      mdrRows2w2s,
      mdrLevels
    },
  } = sanitisedInputs;

  const PALLETS_PER_VIZ = {
    PALLET: 1,
    ROLLBOX_PALLET_1: 1,
    ROLLBOX_PALLET_2: 1,
    NESTING_RACK_1P_1S: 1,
    NESTING_RACK_1P_2S: 2,
    NESTING_RACK_2P_1S: 2,
    NESTING_RACK_2P_2S: 4,
    PALLET_RACK_1P_1S: 1,
    PALLET_RACK_2P_1S: 2,
    PALLET_RACK_1P_2S: 2,
    PALLET_RACK_2P_2S: 4,
    MDR_1W_1S: 1,
    MDR_2W_1S: 2,
    MDR_1W_2S: 2,
    MDR_2W_2S: 4,
    FOLDING_CONTAINER_1S: 1,
    FOLDING_CONTAINER_2S: 2,
    CARTON_BOX_1S: 1,
    CARTON_BOX_2S: 2,
  }

  const MDR_BOXES_PER_UNIT = {
    MDR_1W_1S: mdrRows1w1s * mdrLevels,
    MDR_2W_1S: mdrRows2w1s * mdrLevels,
    MDR_1W_2S: mdrRows1w2s * mdrLevels,
    MDR_2W_2S: mdrRows2w2s * mdrLevels,
  };

  const getCountForType = (outputType) => {
    const {
      storageItems,
      levels,
    } = newOutput[outputType];
    const singleLevelCount = _.reduce(storageItems, (sum, s) => sum + PALLETS_PER_VIZ[s.viz_type], 0);
    if(outputType === "pallet_nr") {
      return (levels + 1) * singleLevelCount;
    }
    return levels * singleLevelCount;
  }

  const mdrUnitCount = newOutput.piece_mdr.levels * _.reduce(newOutput.piece_mdr.storageItems, (sum, s) => sum + MDR_BOXES_PER_UNIT[s.viz_type], 0)

  const pallet_counts = {
    pallet_direct: getCountForType("pallet_direct"),
    pallet_pr: getCountForType("pallet_pr"),
    pallet_nr: getCountForType("pallet_nr"),
    rollbox_direct: getCountForType("rollbox_direct"),
    piece_mdr: getCountForType("piece_mdr"),
    folding_container: getCountForType("folding_container"),
  }
  const spanArea = sanitisedInputs.pillarSpanWidth * sanitisedInputs.pillarSpanDepth;
  const floorArea = spanArea * sanitisedInputs.fireCompartmentWidthSections * sanitisedInputs.fireCompartmentDepthSections;
  const spanVolume = spanArea * sanitisedInputs.ceilingHeight;
  const spaceVolume = floorArea * sanitisedInputs.ceilingHeight;

  const getFloorOccupancyRate = (outputType) => {
    const {
      storageItems,
    } = newOutput[outputType];
    const totalArea = _.reduce(storageItems, (sum, s) => (sum + s.width * s.depth), 0);
    return totalArea * 100 / floorArea;
  }

  const floor_rates = {
    pallet_direct: getFloorOccupancyRate("pallet_direct"),
    pallet_pr: getFloorOccupancyRate("pallet_pr"),
    pallet_nr: getFloorOccupancyRate("pallet_nr"),
    rollbox_direct: getFloorOccupancyRate("rollbox_direct"),
    piece_mdr: getFloorOccupancyRate("piece_mdr"),
    folding_container: getFloorOccupancyRate("folding_container"),
  };

  const space_rates = _.mapValues(floor_rates, (rate, key) => {
    const {
      storageItems,
      levels,
    } = newOutput[key];
    return rate * levels * storageItems[0]?.height / sanitisedInputs.ceilingHeight;
  });

  const display = {
    width: inputs.pillarSpanWidth,
    depth: inputs.pillarSpanDepth,
    ceilingHeight: inputs.ceilingHeight,
    spanArea,
    spanVolume,
    area: floorArea,
    volume: spaceVolume,

    pallet_direct: {
      count: pallet_counts.pallet_direct,
      density: (pallet_counts.pallet_direct * 3.31) / floorArea,
      cost: sanitisedInputs.monthlyRentPerUnit * floorArea / (3.31 * pallet_counts.pallet_direct)
    },
    pallet_pr: {
      count: pallet_counts.pallet_pr,
      density: (pallet_counts.pallet_pr * 3.31) / floorArea,
      cost: sanitisedInputs.monthlyRentPerUnit * floorArea / (3.31 * pallet_counts.pallet_pr)
    },
    pallet_nr: {
      count: pallet_counts.pallet_nr,
      density: (pallet_counts.pallet_nr * 3.31) / floorArea,
      cost: sanitisedInputs.monthlyRentPerUnit * floorArea / (3.31 * pallet_counts.pallet_nr)
    },
    rollbox_direct: {
      count: pallet_counts.rollbox_direct,
      density: (pallet_counts.rollbox_direct * 3.31) / floorArea,
      cost: sanitisedInputs.monthlyRentPerUnit * floorArea / (3.31 * pallet_counts.rollbox_direct)
    },
    piece_mdr: {
      count: pallet_counts.piece_mdr,
      density: (pallet_counts.piece_mdr * 3.31) / floorArea,
      cost: sanitisedInputs.monthlyRentPerUnit * floorArea / (3.31 * pallet_counts.piece_mdr),
      unitsPerTsubo: mdrUnitCount * 3.31 / floorArea,
      costPerUnit: sanitisedInputs.monthlyRentPerUnit * floorArea / mdrUnitCount,
    },
    folding_container: {
      count: pallet_counts.folding_container,
      density: (pallet_counts.folding_container * 3.31) / floorArea,
      cost: sanitisedInputs.monthlyRentPerUnit * floorArea / (3.31 * pallet_counts.folding_container),
      unitsPerTsubo: pallet_counts.folding_container * 3.31 / floorArea,
      costPerUnit: sanitisedInputs.monthlyRentPerUnit * floorArea / (pallet_counts.folding_container),
    },
  };

  return (
    <>
      {/* First row*/}
      <div className="output-row">
        <div className="input-row-text">
          <Trans t={t} i18nKey="psch" />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Width" />
          </div>
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={display.width}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Depth" />
          </div>
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={display.depth}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="efch" />
          </div>
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={display.ceilingHeight}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Floor load capacity" />
          </div>
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={"1.5t/m3"}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Floor area" />
          </div>
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${display.spanArea.toFixed(2)} m2`}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Space volume" />
          </div>
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${display.spanVolume.toFixed(2)} m3`}
          />
        </div>
      </div>

      {/* Second row*/}
      <div className="output-row">
        <div className="input-row-text">
          <Trans t={t} i18nKey="Fire compartment" />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Width sections" />
          </div>
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${inputs.fireCompartmentWidthSections}スパン`}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Depth sections" />
          </div>
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${inputs.fireCompartmentDepthSections}スパン`}
          />
        </div>
        <div className="input-wrapper">

        </div>
        <div className="input-wrapper">

        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Floor area" />
          </div>
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${display.area.toFixed(2)} m2`}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="Space volume" />
          </div>
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${display.volume.toFixed(2)} m2`}
          />
        </div>
      </div>

      {/* Third row*/}
      <div className="output-row">
        <div className="input-row-text">
          <Trans t={t} i18nKey="mrpu" />
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Trans t={t} i18nKey="unitPrice" />
          </div>
          <DecimalInput
            readOnly
            styleClasses="bglo"
            value={`${inputs.monthlyRentPerUnit}円/坪`}
          />
        </div>
        <div className="input-wrapper">

        </div>
        <div className="input-wrapper">

        </div>
        <div className="input-wrapper">

        </div>
        <div className="input-wrapper">

        </div>
        <div className="input-wrapper">

        </div>
      </div>

      {/* Divider */}
      <div className="output-row">
        <div className="input-row-text">

        </div>
        <div className="input-wrapper fg3">
          <DecimalInput
            readOnly
            styleClasses="bgbl"
            value={t("Pallet")}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgbl"
            value={t("Rollbox pallet")}
          />
        </div>
        <div className="input-wrapper fg2">
          <DecimalInput
            readOnly
            styleClasses="bgbl"
            value={t("Medium duty rack")}
          />
        </div>
        <div className="input-wrapper fg2">
          <DecimalInput
            readOnly
            styleClasses="bgbl"
            value={t("Folding containers")}
          />
        </div>
      </div>

      {/* Divider */}
      <div className="output-row">
        <div className="input-row-text">

        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bggr"
            value="直置き"
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bggr"
            value="パレットラック（重量棚）"
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bggr"
            value="逆ネステナー"
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bggr"
            value="直置き"
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bggr"
            value="ボリューム"
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bggr"
            value="巣箱数"
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bggr"
            value="ボリューム"
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bggr"
            value="ケース数"
          />
        </div>
      </div>

      {/*Total pallets*/}
      <div className="output-row">
        <div className="input-row-text">
          <DecimalInput
            readOnly
            styleClasses="bgbl"
            value={t("Total pallets")}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${display.pallet_direct.count}枚`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${display.pallet_pr.count}枚`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${display.pallet_nr.count}枚`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${display.rollbox_direct.count}枚`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${display.piece_mdr.count}枚`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${mdrUnitCount}箱`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgw"
            value={""}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgs"
            value={`${display.folding_container.count}個`}
          />
        </div>

      </div>

      {/*Pallets/tsubo*/}
      <div className="output-row">
        <div className="input-row-text">
          <DecimalInput
            readOnly
            styleClasses="bgbl"
            value={t("Pallets/Tsubo")}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgb"
            value={`${display.pallet_direct.density.toFixed(2)}枚/坪`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgb"
            value={`${display.pallet_pr.density.toFixed(2)}枚/坪`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgb"
            value={`${display.pallet_nr.density.toFixed(2)}枚/坪`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgb"
            value={`${display.rollbox_direct.density.toFixed(2)}枚/坪`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgb"
            value={"2.33㎥/坪"}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgb"
            value={`${display.piece_mdr.unitsPerTsubo.toFixed(2)}箱/坪`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgb"
            value={"1.95㎥/坪"}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgb"
            value={`${display.folding_container.unitsPerTsubo.toFixed(2)}箱/坪`}
          />
        </div>
      </div>

      {/*Storage cost*/}
      <div className="output-row">
        <div className="input-row-text">
          <DecimalInput
            readOnly
            styleClasses="bgbl"
            value={t("Storage cost")}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgo cw"
            value={`${display.pallet_direct.cost.toFixed(2)}円/枚`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgo cw"
            value={`${display.pallet_pr.cost.toFixed(2)}円/枚`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgo cw"
            value={`${display.pallet_nr.cost.toFixed(2)}円/枚`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgo cw"
            value={`${display.rollbox_direct.cost.toFixed(2)}円/枚`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgo cw"
            value={"1,718円/㎥"}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgo cw"
            value={`${display.piece_mdr.costPerUnit.toFixed(2)}円/箱`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgo cw"
            value={"2,054円/㎥"}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgo cw"
            value={`${display.folding_container.costPerUnit.toFixed(2)}円/個`}
          />
        </div>
      </div>

      {/*Floor occupancy rate*/}
      <div className="output-row">
        <div className="input-row-text">
          <DecimalInput
            readOnly
            styleClasses="bgbl"
            value={t("Floor occupancy rate")}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${floor_rates.pallet_direct.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${floor_rates.pallet_pr.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${floor_rates.pallet_nr.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${floor_rates.rollbox_direct.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${floor_rates.piece_mdr.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgw"
            value={""}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${floor_rates.folding_container.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgw"
            value={""}
          />
        </div>
      </div>

      {/*Floor occupancy rate*/}
      <div className="output-row">
        <div className="input-row-text">
          <DecimalInput
            readOnly
            styleClasses="bgbl"
            value={t("Space occupancy rate")}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${space_rates.pallet_direct.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${space_rates.pallet_pr.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${space_rates.pallet_nr.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${space_rates.rollbox_direct.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${space_rates.piece_mdr.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgw"
            value={""}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgg"
            value={`${space_rates.folding_container.toFixed(2)}%`}
          />
        </div>
        <div className="input-wrapper">
          <DecimalInput
            readOnly
            styleClasses="bgw"
            value={""}
          />
        </div>
      </div>
    </>
  )
};

export default withTranslation()(OutputSection);
