import React from "react";
import _ from "lodash";
import {STORAGE_METHODS} from "../../../utils";
import {Trans, withTranslation} from "react-i18next";
import StorageSpecs from "./storage_specs";

const StorageMethods = ({ inputs, updateValue, t, canEnterCargoSpecs }) => {
  const availableStorageMethods = _.filter(STORAGE_METHODS, s => _.includes(s.cargo_types, inputs.cargoType));
  return (
    <div className="input-margin-section">
      {
        availableStorageMethods.map(s => {
          const rowContent = (
            <div className="input-row" key={s.text}>
              <div className="input-row-text">
                <label className="checkbox-container">
                  <Trans t={t} i18nKey={s.text} />
                  <input type="checkbox" checked={inputs.storageMethod === s.text} onChange={() => {
                    updateValue({
                      target: {
                        value: s.text,
                        dataset: {
                          path: "storageMethod"
                        }
                      }
                    })
                  }} />
                  <span className="checkbox-mark"></span>
                </label>
              </div>
              <div className="input-wrapper" />
              <div className="input-wrapper" />
            </div>
          );
          const CollapsedContent = <StorageSpecs canEnterCargoSpecs={canEnterCargoSpecs} storage={s} inputs={inputs} updateValue={updateValue} />;
          if(s.options.length > 0) {
            return (
              <details key={s.text} className="input-collapse">
                <summary className="input-collapse-head">
                  {rowContent}
                </summary>
                {CollapsedContent}
              </details>
            )
          }
          return <div className="ml-17" key={s.text}>
            {rowContent}
          </div>;
        })
      }
    </div>
  );
}

export default withTranslation()(StorageMethods);
