import React from "react"

import { ListItemIcon } from "@mui/material"
import {
  Home,
  Settings,
  People,
  Person,
  Apartment,
  Warehouse
} from '@mui/icons-material/'

const Icon = (props) => {
  const { iconName } = props

  return (
    <ListItemIcon>
      {iconName === 'home' && (<Home />)}
      {iconName === 'management' && (<Settings />)}
      {iconName === 'people' && (<People />)}
      {iconName === 'person' && <Person />}
      {iconName === 'company' && (<Apartment />)}
      {iconName === 'warehouse' && (<Warehouse />)}
    </ListItemIcon>
  )
}

export default Icon