import React, { useContext } from 'react'

import { AuthContext } from '../../../../app'

import {
  ADD_USER_BUTTON_LABEL_I18NKEY,
  USER_THEAD,
  USER_SORT
} from './constants'
import {
  apiGetAllUsers,
  apiGetUserFromCompanyId,
  apiDeleteUser,
  apiDeleteWarehouseAssignFromUserId
} from '../../../../utils/api'

import EditUserModal from './EditUserModal'
import ManageListTemplate from '../../../templates/ManageListTemplate'

const UsersPage = () => {
  const { userDetails } = useContext(AuthContext)
  const companyId = userDetails['company_id']
  const userRole = userDetails['role']

  const canEdit = (userRole === 'admin' || userRole === 'manager')
  const canDelete = (userRole === 'admin' || userRole === 'manager')
  const isOnlySameCompany = userRole !== 'admin'

  const getAllUserOrInSameCompanyApi = () => {
    if (isOnlySameCompany) {
      return apiGetUserFromCompanyId(companyId)
    } else {
      return apiGetAllUsers()
    }
  }

  const deleteUserAndWarehouseAssignApi = async ({ id }) => {
    await apiDeleteUser({ id })
    await apiDeleteWarehouseAssignFromUserId({ user_id: id })
  }

  const props = {
    fetchDataApi: getAllUserOrInSameCompanyApi,
    deleteDataApi: deleteUserAndWarehouseAssignApi,
    ADD_BUTTON_LABEL_I18NKEY: ADD_USER_BUTTON_LABEL_I18NKEY,
    CONST_THEAD: USER_THEAD.map(head => head),
    CONST_SORT: USER_SORT,
    canEdit: canEdit,
    canDelete: canDelete,
    EditModal: EditUserModal,
  }

  return <ManageListTemplate props={props} />
}

export default UsersPage
