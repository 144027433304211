import React from 'react'

import json from '../../revision.json'

const RevisionDisplay = () => {
  const { revision } = json

  return (
    <div style={{
      position: 'absolute',
      bottom: '10px',
      left: '10px',
    }}>
      <label>
        {`Rev: ${revision}`}
      </label>
    </div>
  )
}

export default RevisionDisplay
