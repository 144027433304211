import React from 'react';

import Logo from '../../assets/asoko_logo.png';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import '../../style/login.scss';

const LoginHoc = ({ children, wide }) => (
  <div id="login-background">
    <div id="login-container" className={classNames({ wide })}>
      <div id="login-modal">
        <Link to="/">
          <img src={Logo} alt="Celestar logo" id="login-logo" />
        </Link>
        {children}
      </div>
      <div id="login-captcha-text">
        This site is protected by reCAPTCHA and the Google{' '}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </div>
  </div>
);

export default LoginHoc;
