import React  from "react";
import { useGLTF } from "@react-three/drei";

export function PalletRack2P1S(props) {
  const { nodes, materials } = useGLTF("/new_models/pr_2x_1y.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Cube005.geometry}
        material={materials["Material.001"]}
      />
    </group>
  );
}

useGLTF.preload("/new_models/pr_2x_1y.glb");

export default PalletRack2P1S;
