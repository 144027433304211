import React from "react"
import { Trans, withTranslation, useTranslation } from 'react-i18next'

const H2Title = (props) => {
  const { t } = useTranslation()
  const {
    type='default',
    decoration='',
    i18nKey,
    marginBottom='',
    marginTop='',
  } = props

  const className = () => {
    switch(type) {
      case 'subtitle':
        return 'subtitle-bar'
      case 'home-menu':
        return 'home-menu-bar'
      default:
        return ''
    }
  }

  return (
    <h2
      className={className()}
      style={{
        marginBottom: marginBottom,
        marginTop: marginTop,
      }}
    >
      {decoration}<Trans t={t} i18nKey={i18nKey} />
    </h2>
  )
}

export default withTranslation()(H2Title)
