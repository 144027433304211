import React from "react";

const Pillar = ({
  width, depth, height, pos
}) => {
  return (
    <mesh position={[pos[0], height / 2, pos[1]]}>
      <boxGeometry args={[width, height, depth]} />
      <meshStandardMaterial color={"#444444"} />
    </mesh>
  );
}

export default Pillar;
