import {getIntermediateColumns, getItemsInArea, getVizTypeForCargoAndStorage, transformSanitizeNumbers} from "./math";
import {
  ARRANGEMENT_DIRECTIONS, ENUM_CARGO_TYPES,
  ENUM_LAYOUT_COLUMN_TYPES, ENUM_STORAGE_METHODS,
  ENUM_STORAGE_SIZE_TYPES,
  WALL_TYPES,
  WALLS_FOR_SECTION_COUNT,
  NON_EMPTY_INPUTS
} from "./index";
import _ from "lodash";

const getDirectStorageOutput = (inputs, cargoInfo) => {
  const {
    width: itemWidth,
    depth: itemDepth,
    levels,
    height,
    type: cargoType,
    option: cargoOption,
  } = cargoInfo;

  const {
    fireCompartmentWidthSections,
    fireCompartmentDepthSections,
    pillarSpanWidth,
    pillarSizeWidth,
    pillarSpanDepth,
    pillarSizeDepth,
    mainPassagewayWidth,
    clearancePillar,
    clearanceFireshutter,
    clearanceFirewall,
    spandrelWallThickness,
    arrangementDirection,
  } = inputs;

  // Along is towards the shelves. Aside is perpendicular direction
  let alongSections = fireCompartmentDepthSections;
  let asideSections = fireCompartmentWidthSections;
  let alongPillarSpan = pillarSpanDepth;
  let alongPillarSize = pillarSizeDepth;
  let asidePillarSpan = pillarSpanWidth;
  let asidePillarSize = pillarSizeWidth;

  if(arrangementDirection === ARRANGEMENT_DIRECTIONS[1].id) {
    alongSections = fireCompartmentWidthSections;
    asideSections = fireCompartmentDepthSections;
    alongPillarSpan = pillarSpanWidth;
    alongPillarSize = pillarSizeWidth;
    asidePillarSpan = pillarSpanDepth;
    asidePillarSize = pillarSizeDepth;
  }

  const floorWidth = asidePillarSpan * asideSections;
  const floorDepth = alongPillarSpan * alongSections;
  const clearanceCalc = {
    pillar: asidePillarSize * 0.5 + clearancePillar,
    shutter: clearanceFireshutter,
    wall: spandrelWallThickness * 0.5 + clearanceFirewall,
  };

  let storageAreas = [];
  let storageItems = [];
  const horizontalPassageways = [];
  const verticalPassageways = [];

  const X_WALL_TYPES = WALLS_FOR_SECTION_COUNT[asideSections];
  const Y_WALL_TYPES = WALLS_FOR_SECTION_COUNT[alongSections];

  // Position of horizontal passageways is same for all types of storage - Direct and indirect
  for(let currentRow = 0; currentRow < alongSections; currentRow += 1) {
    if(Y_WALL_TYPES[currentRow] === WALL_TYPES.SHUTTER) {
      if(Y_WALL_TYPES[currentRow - 1] === Y_WALL_TYPES[currentRow]) {
        horizontalPassageways.push({
          top: alongPillarSpan * currentRow + alongPillarSize / 2,
          bottom: alongPillarSpan * currentRow + mainPassagewayWidth + alongPillarSize / 2,
          left: 0,
          right: floorWidth,
        });
      } else {
        horizontalPassageways.push({
          top: alongPillarSpan * (currentRow + 1) - mainPassagewayWidth - alongPillarSize / 2,
          bottom: alongPillarSpan * (currentRow + 1) - alongPillarSize / 2,
          left: 0,
          right: floorWidth,
        });
      }
    }
  }

  for(let currentColumn = 0; currentColumn < asideSections; currentColumn += 1) {
    for(let currentRow = 0; currentRow < 2; currentRow += 1) {
      let left, right, top, bottom = 0;

      if(currentColumn === 0) {
        // First column
        // TODO: Figure out if pillar clearance should be considered
        left = Math.max(clearanceCalc.shutter, clearanceCalc.pillar);
        right = asidePillarSpan - mainPassagewayWidth - asidePillarSize / 2;
      } else if(currentColumn === asideSections - 1) {
        // Last column
        // TODO: Figure out if pillar clearance should be considered
        left = asidePillarSpan * currentColumn + asidePillarSize / 2 + mainPassagewayWidth;
        right = floorWidth - Math.max(clearanceCalc.shutter, clearanceCalc.pillar);
      } else {
        // Other columns
        if(currentColumn % 2 === 0) {
          left = asidePillarSpan * currentColumn + clearanceCalc.pillar;
          right = asidePillarSpan * (currentColumn + 1) - mainPassagewayWidth - asidePillarSize / 2;
        } else {
          left = asidePillarSpan * currentColumn + asidePillarSize / 2 + mainPassagewayWidth;
          right = asidePillarSpan * (currentColumn + 1) - clearanceCalc.pillar;
        }
      }

      if(currentRow === 0) {
        // Top row above passageways
        top = X_WALL_TYPES[currentColumn] === WALL_TYPES.WALL ?
          clearanceCalc.wall:
          clearanceCalc.shutter;
        bottom = _.head(horizontalPassageways).top;
      } else {
        // Bottom row above passageways
        top = _.last(horizontalPassageways).bottom;
        bottom = floorDepth - (X_WALL_TYPES[currentColumn] === WALL_TYPES.WALL ?
          clearanceCalc.wall:
          clearanceCalc.shutter);
      }
      storageAreas.push({ left, right, top, bottom });
    }

    if(currentColumn === 0) {
      // First column
      verticalPassageways.push({
        left: asidePillarSpan - mainPassagewayWidth - asidePillarSize / 2,
        right: asidePillarSpan - asidePillarSize / 2,
      });
    } else if(currentColumn === asideSections - 1) {
      // Last column
      verticalPassageways.push({
        left: floorWidth - asidePillarSpan + asidePillarSize / 2,
        right: asidePillarSpan * currentColumn + asidePillarSize / 2 + mainPassagewayWidth
      });
    } else {
      // Other columns
      if(currentColumn % 2 === 0) {
        verticalPassageways.push({
          left: asidePillarSpan * (currentColumn + 1) - mainPassagewayWidth - asidePillarSize / 2,
          right: asidePillarSpan * (currentColumn + 1) - asidePillarSize / 2
        });
      } else {
        verticalPassageways.push({
          left: asidePillarSpan * currentColumn + asidePillarSize / 2,
          right: asidePillarSpan * currentColumn + asidePillarSize / 2 + mainPassagewayWidth,
        });
      }
    }
  }

  storageAreas.forEach(area => {
    const areaWidth = area.right - area.left;
    const areaDepth = area.bottom - area.top;
    const columnCount = Math.floor(areaWidth/itemWidth);
    const rowCount = Math.floor(areaDepth/itemDepth);
    for(let c = 0; c < columnCount; c++) {
      for(let r = 0; r < rowCount; r++) {
        storageItems.push({
          left: area.left + c * (itemWidth),
          top: area.top + r * (itemDepth),
          height,
          viz_type: getVizTypeForCargoAndStorage({
            cargoType,
            cargoOption,
          }),
          width: itemWidth,
          depth: itemDepth
        });
      }
    }
  });

  return {
    storageItems,
    levels,
    horizontalPassageways,
  };
}

const getLinearStorageOutput = (inputs, storageSpecs, storageMethod,
) => {
  const {
    levels,
    height,
  } = storageSpecs;

  const {
    fireCompartmentWidthSections,
    fireCompartmentDepthSections,
    pillarSpanWidth,
    pillarSizeWidth,
    pillarSpanDepth,
    pillarSizeDepth,
    mainPassagewayWidth,
    clearancePillar,
    clearanceFireshutter,
    clearanceFirewall,
    spandrelWallThickness,
    arrangementDirection,
    subPassagewayWidth,
  } = inputs;

  // Along is towards the shelves. Aside is perpendicular direction
  let alongSections = fireCompartmentDepthSections;
  let asideSections = fireCompartmentWidthSections;
  let alongPillarSpan = pillarSpanDepth;
  let alongPillarSize = pillarSizeDepth;
  let asidePillarSpan = pillarSpanWidth;
  let asidePillarSize = pillarSizeWidth;
  let alongStorageSizeSingle = storageSpecs.singleDepth;
  let asideStorageSizeSingle = storageSpecs.singleWidth;
  let alongStorageSizeDouble = storageSpecs.doubleDepth;
  let asideStorageSizeDouble = storageSpecs.doubleWidth;

  if(arrangementDirection === ARRANGEMENT_DIRECTIONS[1].id) {
    alongSections = fireCompartmentWidthSections;
    asideSections = fireCompartmentDepthSections;
    alongPillarSpan = pillarSpanWidth;
    alongPillarSize = pillarSizeWidth;
    asidePillarSpan = pillarSpanDepth;
    asidePillarSize = pillarSizeDepth;
    // alongStorageSizeSingle = storageSpecs.singleWidth;
    // asideStorageSizeSingle = storageSpecs.singleDepth;
    // alongStorageSizeDouble = storageSpecs.doubleWidth;
    // asideStorageSizeDouble = storageSpecs.doubleDepth;
  }

  const floorWidth = asidePillarSpan * asideSections;
  const clearanceCalc = {
    pillar: asidePillarSize * 0.5 + clearancePillar,
    shutter: clearanceFireshutter,
    wall: spandrelWallThickness * 0.5 + clearanceFirewall,
  };

  let storageAreas = [];
  let storageItems = [];
  const horizontalPassageways = [];

  const X_WALL_TYPES = WALLS_FOR_SECTION_COUNT[asideSections];
  const Y_WALL_TYPES = WALLS_FOR_SECTION_COUNT[alongSections];

  // Position of horizontal passageways is same for all types of storage - Direct and indirect
  for(let currentRow = 0; currentRow < alongSections; currentRow += 1) {
    if(Y_WALL_TYPES[currentRow] === WALL_TYPES.SHUTTER) {
      if(Y_WALL_TYPES[currentRow - 1] === Y_WALL_TYPES[currentRow]) {
        horizontalPassageways.push({
          top: alongPillarSpan * currentRow + alongPillarSize / 2,
          bottom: alongPillarSpan * currentRow + mainPassagewayWidth + alongPillarSize / 2,
          left: 0,
          right: floorWidth,
        });
      } else {
        horizontalPassageways.push({
          top: alongPillarSpan * (currentRow + 1) - mainPassagewayWidth - alongPillarSize / 2,
          bottom: alongPillarSpan * (currentRow + 1) - alongPillarSize / 2,
          left: 0,
          right: floorWidth,
        });
      }
    }
  }


  let doubleWidth = alongStorageSizeDouble,
    singleWidth = alongStorageSizeSingle,
    doubleDepth = asideStorageSizeDouble,
    singleDepth = asideStorageSizeSingle;
  let columns = [];
  let intermediateColumns = [];
  for(let currentColumn = 0; currentColumn < asideSections; currentColumn += 1) {
    if(currentColumn === 0) {
      columns = [
        {
          size: ENUM_STORAGE_SIZE_TYPES.SINGLE,
          left: Math.max(clearanceCalc.shutter, clearanceCalc.pillar),
          type: ENUM_LAYOUT_COLUMN_TYPES.WALL,
        },
        {
          size: ENUM_STORAGE_SIZE_TYPES.DOUBLE,
          left: asidePillarSpan - doubleWidth / 2,
          type: ENUM_LAYOUT_COLUMN_TYPES.PILLAR,
        }
      ];
      intermediateColumns = getIntermediateColumns({
        left: Math.max(clearanceCalc.shutter, clearanceCalc.pillar) + singleWidth,
        right: asidePillarSpan - doubleWidth / 2,
        singleWidth, doubleWidth, subPassagewayWidth,
        clearanceType: ENUM_LAYOUT_COLUMN_TYPES.WALL,
      });
    } else { // Don't pack at columns where there's a shutter
      if(currentColumn === asideSections - 1) {
        columns.push({
          size: ENUM_STORAGE_SIZE_TYPES.SINGLE,
          left: asidePillarSpan * asideSections - Math.max(clearanceCalc.shutter, clearanceCalc.pillar) - singleWidth,
          type: ENUM_LAYOUT_COLUMN_TYPES.WALL,
          rotation: Math.PI,
        });
        intermediateColumns = getIntermediateColumns({
          left: currentColumn * asidePillarSpan + doubleWidth / 2,
          right: asideSections * asidePillarSpan - Math.max(clearanceCalc.shutter, clearanceCalc.pillar) - singleWidth,
          singleWidth, doubleWidth, subPassagewayWidth,
          clearanceType: ENUM_LAYOUT_COLUMN_TYPES.WALL,
        });
      } else {
        columns.push({
          size: ENUM_STORAGE_SIZE_TYPES.DOUBLE,
          left: (currentColumn + 1) * asidePillarSpan - doubleWidth / 2,
          type: ENUM_LAYOUT_COLUMN_TYPES.PILLAR,
        });
        intermediateColumns = getIntermediateColumns({
          left: currentColumn * asidePillarSpan + doubleWidth / 2,
          right: (currentColumn + 1) * asidePillarSpan - doubleWidth / 2,
          singleWidth, doubleWidth, subPassagewayWidth,
          clearanceType: X_WALL_TYPES[currentColumn] === WALL_TYPES.WALL ? ENUM_LAYOUT_COLUMN_TYPES.WALL : ENUM_LAYOUT_COLUMN_TYPES.SHUTTER,
        });
      }
    }
    columns = [
      ...columns,
      ...intermediateColumns,
    ];
  }

  // Loop through columns and get storage areas
  columns.forEach((col, index) => {
    if(col.type !== ENUM_LAYOUT_COLUMN_TYPES.PILLAR) {
      storageAreas = [
        ...storageAreas,
        {
          left: col.left,
          size: col.size,
          top: col.type === ENUM_LAYOUT_COLUMN_TYPES.WALL ?
            clearanceCalc.wall:
            clearanceCalc.shutter,
          bottom: _.head(horizontalPassageways).top,
          rotation: (col.rotation || 0)
        },
        {
          left: col.left,
          size: col.size,
          top: _.last(horizontalPassageways).bottom,
          bottom: alongPillarSpan * alongSections - (col.type === ENUM_LAYOUT_COLUMN_TYPES.WALL ?
            clearanceCalc.wall:
            clearanceCalc.shutter),
          rotation: (col.rotation || 0)
        },
      ];
    } else {
      for(let i = 0; i < alongSections; i++) {
        if(Y_WALL_TYPES[i] === WALL_TYPES.WALL) {
          storageAreas.push({
            left: col.left,
            size: col.size,
            top: i * alongPillarSpan + clearanceCalc.pillar,
            bottom: (i + 1) * alongPillarSpan - clearanceCalc.pillar,
          });
        } else {
          if(Y_WALL_TYPES[i - 1] === Y_WALL_TYPES[i]) {
            // Bottom passageway
            storageAreas.push({
              left: col.left,
              size: col.size,
              top: i * alongPillarSpan + mainPassagewayWidth,
              bottom: (i + 1) * alongPillarSpan - clearanceCalc.pillar
            });
          } else {
            // Top Passageway
            storageAreas.push({
              left: col.left,
              size: col.size,
              top: i * alongPillarSpan + clearanceCalc.pillar,
              bottom: (i + 1) * alongPillarSpan - mainPassagewayWidth,
            });
          }
        }
      }
    }
  });

  storageAreas.forEach(area => {
    storageItems = [
      ...storageItems,
      ...getItemsInArea({
        size: area.size,
        top: area.top,
        left: area.left,
        bottom: area.bottom,
        doubleWidth,
        singleWidth,
        doubleDepth,
        singleDepth,
        storageMethod,
        height,
        rotation: area.rotation,
      }),
    ];
  });

  return {
    storageItems,
    levels,
    horizontalPassageways,
  };
}

export const getOutput = (rawInputs) => {
  const inputs = transformSanitizeNumbers(rawInputs);

  const {
    cargoType,
    ceilingHeight,
    clearanceBeam,
    // cargoInfoCollapsed,
    storageSpecs,
    palletOption,
    rollboxOption,
    storageMethod,
    fireCompartmentWidthSections,
    fireCompartmentDepthSections,
    pillarSpanWidth,
    pillarSpanDepth,
  } = inputs;

  const errors = [];
  _.forEach(NON_EMPTY_INPUTS,(val, prop) => {
    const value = _.get(inputs, prop);
    if(value === 0 || value === "") {
      errors.push(val);
    }
  });

  if(errors.length > 0) {
    return { errors: _.uniq(errors) };
  }

  const availableHeight = ceilingHeight - clearanceBeam;

  const palletCargoSpecs = inputs.cargoSpecs; // cargoInfoCollapsed[inputs.palletOption];
  const rollboxCargoSpecs = inputs.cargoSpecs; // cargoInfoCollapsed[inputs.rollboxOption];

  let cargoSpecs = {};
  if(cargoType === ENUM_CARGO_TYPES.pallet) {
    cargoSpecs = palletCargoSpecs;
  } else if(cargoType === ENUM_CARGO_TYPES.rollbox_pallet) {
    cargoSpecs = rollboxCargoSpecs;
  }

  const fcMaxLevels = Math.floor(availableHeight / storageSpecs.fcHeight);

  const output = {
    pallet_direct: getDirectStorageOutput(inputs, {
      ...palletCargoSpecs,
      type: ENUM_CARGO_TYPES.pallet,
      option: palletOption,
      levels: Math.floor(availableHeight / palletCargoSpecs.height)
    }, ENUM_STORAGE_METHODS.direct),
    pallet_pr: getLinearStorageOutput(inputs, {
      singleDepth: storageSpecs.prSingleDepth,
      singleWidth: storageSpecs.prSingleWidth,
      doubleDepth: storageSpecs.prDoubleDepth,
      doubleWidth: storageSpecs.prDoubleWidth,
      height: storageSpecs.prHeight / 3,
      levels: 3
    }, ENUM_STORAGE_METHODS.pallet_rack),
    pallet_nr: getLinearStorageOutput(inputs, {
      singleDepth: storageSpecs.nrSingleDepth,
      singleWidth: storageSpecs.nrSingleWidth,
      doubleDepth: storageSpecs.nrDoubleDepth,
      doubleWidth: storageSpecs.nrDoubleWidth,
      height: storageSpecs.nrHeight,
      levels: Math.floor((availableHeight - palletCargoSpecs.height) / storageSpecs.nrHeight)
    }, ENUM_STORAGE_METHODS.nesting_rack),
    rollbox_direct: getDirectStorageOutput(inputs, {
      ...rollboxCargoSpecs,
      type: ENUM_CARGO_TYPES.rollbox_pallet,
      option: rollboxOption,
      levels: 1,
    }, ENUM_STORAGE_METHODS.direct),
    piece_mdr: getLinearStorageOutput(inputs, {
      singleDepth: storageSpecs.mdrSingleDepth,
      singleWidth: storageSpecs.mdrSingleWidth,
      doubleDepth: storageSpecs.mdrDoubleDepth,
      doubleWidth: storageSpecs.mdrDoubleWidth,
      height: storageSpecs.mdrHeight / storageSpecs.mdrLevels,
      levels: storageSpecs.mdrLevels,
    }, ENUM_STORAGE_METHODS.medium_duty_rack),
    folding_container: getLinearStorageOutput(inputs, {
      singleDepth: storageSpecs.fcSingleDepth,
      singleWidth: storageSpecs.fcSingleWidth,
      doubleDepth: storageSpecs.fcDoubleDepth,
      doubleWidth: storageSpecs.fcSingleWidth,
      height: storageSpecs.fcHeight,
      levels: storageSpecs.fcLevels < fcMaxLevels ? storageSpecs.fcLevels : fcMaxLevels,
    }, storageMethod === ENUM_STORAGE_METHODS.folding_containers ? ENUM_STORAGE_METHODS.folding_containers : ENUM_STORAGE_METHODS.carton_cases),
    cargoSpecs,
  };
  const palletsInLevel = output.pallet_direct.storageItems.length;
  const levelWeight = palletsInLevel * output.cargoSpecs.weight / 1000;
  const leveWeightPerArea = levelWeight / (fireCompartmentWidthSections * fireCompartmentDepthSections * pillarSpanWidth * pillarSpanDepth);
  const weightLevelsLimit = Math.floor(inputs.floorLoadCapacity / leveWeightPerArea);
  output.pallet_direct.levels = Math.min(weightLevelsLimit, output.pallet_direct.levels);
  // console.log(output);
  return output;
}