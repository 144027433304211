import React from 'react'

import Button from '../atoms/Button'

const ModalButtonsSet = (props) => {
  const { confirmButtonI18nKey, submitHandler, cancelHandler, disabled=false } = props

  return (
    <div className="flex">
      <div className="half-width mr-4">
        <Button
          color='primary'
          i18nKey={confirmButtonI18nKey}
          clickHandler={submitHandler}
          disabled={disabled}
        />
      </div>
      <div className="half-width ml-4">
        <Button
          color='default'
          i18nKey="cancel"
          clickHandler={cancelHandler}
        />
      </div>
    </div>
  )
}

export default ModalButtonsSet
