import React from 'react'
import Cookies from 'js-cookie'
import { Trans } from 'react-i18next'

const ModelRow = (props) => {
  const { u, isTransparent } = props
  const lang = Cookies.get('lang') || 'ja'
  const userFirstName = !!u[3] ? u[3] : ''
  const userLastName = !!u[4] ? u[4] : ''
  const updatedUserFullName =
    lang === 'ja'
      ? (`${userLastName} ${userFirstName}`)
      : (`${userFirstName} ${userLastName}`)

  return (
    <tr style={{opacity: isTransparent ? '.5' : '1'}}>
      <td style={{width: '150px'}}><Trans i18nKey={u[0]}/></td>
      <td>{u[1]}</td>
      <td style={{width: '130px'}}>{u[2]}</td>
      <td style={{width: '150px'}}>{updatedUserFullName}</td>
    </tr>
  )
}

export default ModelRow
