import React  from "react";
import { useGLTF } from "@react-three/drei";

export function Mdr2W1S(props) {
  const { nodes, materials } = useGLTF("/new_models/mdr_2x1y.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.mdr_09_2S002.geometry}
        material={materials["mdr_1.8_1S.001"]}
      />
    </group>
  );
}

useGLTF.preload("/new_models/mdr_2x1y.glb");

export default Mdr2W1S;
