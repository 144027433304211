import React  from "react";
import { useGLTF } from "@react-three/drei";

export function Mdr2W2S(props) {
  const { nodes, materials } = useGLTF("/new_models/mdr_2x2y.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.mdr_09_2S003.geometry}
        material={materials["mdr_1.8_1S"]}
      />
    </group>
  );
}

useGLTF.preload("/new_models/mdr_2x2y.glb");

export default Mdr2W2S;
