import React from "react";
import {Trans, withTranslation} from "react-i18next";
// import _ from "lodash";
import DecimalInput from "../../../components/decimal_input";
import Collapsible from "../../../components/collapsible";
// import {ENUM_CARGO_TYPES} from "../../../utils";

const CargoSpecs = ({ cargo, inputs, t, updateValue, canEnterCargoSpecs }) => {

  if(cargo.options.length > 0) {
    return <div className="cargo-info-collapsed-content">
      {
        canEnterCargoSpecs && (
          <>
            <div className="input-row">
              <div className="input-row-text small">
                <Trans t={t} i18nKey="Cargo dimensions" />
              </div>
              <div className="input-wrapper">
                <div className="input-label">
                  <Trans t={t} i18nKey="Width" />
                </div>
                <DecimalInput
                  styleClasses="bob"
                  onChange={updateValue}
                  value={inputs.cargoSpecs.width}
                  path={`cargoSpecs.width`}
                />
                <div className="input-unit">m</div>
              </div>
              <div className="input-wrapper">
                <div className="input-label">
                  <Trans t={t} i18nKey="Depth" />
                </div>
                <DecimalInput
                  styleClasses="bob"
                  onChange={updateValue}
                  value={inputs.cargoSpecs.depth}
                  path={`cargoSpecs.depth`}
                />
                <div className="input-unit">m</div>
              </div>
              <div className="input-wrapper">
                <div className="input-label">
                  <Trans t={t} i18nKey="Height" />
                </div>
                <DecimalInput
                  styleClasses="bob"
                  onChange={updateValue}
                  value={inputs.cargoSpecs.height}
                  path={`cargoSpecs.height`}
                />
                <div className="input-unit">m</div>
              </div>
            </div>
            <div className="input-row">
              <div className="input-row-text small">
                <Trans t={t} i18nKey="Cargo weight" />
              </div>
              <div className="input-wrapper">
                <DecimalInput
                  styleClasses="bob"
                  onChange={updateValue}
                  value={inputs.cargoSpecs.weight}
                  path={`cargoSpecs.weight`}
                />
                <div className="input-unit kg">kg</div>
              </div>
              <div className="input-wrapper" />
              <div className="input-wrapper" />
            </div>
          </>
        )

      }
      <Collapsible heading={<span className="padding-tb">View standard sizes</span>}>
        <div className="collapsible-content">
          {
            cargo.options.map(co => {
              return (
                <div className="input-row" key={co}>
                  <div className="input-row-text small">
                    <Trans t={t} i18nKey={co} />
                  </div>
                  <div className="input-wrapper">
                    <div className="input-label">
                      <Trans t={t} i18nKey="Width" />
                    </div>
                    <DecimalInput
                      styleClasses="bob"
                      onChange={updateValue}
                      value={inputs.cargoInfoCollapsed[co].width}
                      path={`cargoInfoCollapsed[${co}].width`}
                    />
                    <div className="input-unit">m</div>
                  </div>
                  <div className="input-wrapper">
                    <div className="input-label">
                      <Trans t={t} i18nKey="Depth" />
                    </div>
                    <DecimalInput
                      styleClasses="bob"
                      onChange={updateValue}
                      value={inputs.cargoInfoCollapsed[co].depth}
                      path={`cargoInfoCollapsed[${co}].depth`}
                    />
                    <div className="input-unit">m</div>
                  </div>
                  <div className="input-wrapper">
                    <div className="input-label">
                      <Trans t={t} i18nKey="Height" />
                    </div>
                    <DecimalInput
                      styleClasses="bob"
                      onChange={updateValue}
                      value={inputs.cargoInfoCollapsed[co].height}
                      path={`cargoInfoCollapsed[${co}].height`}
                    />
                    <div className="input-unit">m</div>
                  </div>
                  <div className="input-wrapper">
                    <div className="input-label">
                      <Trans t={t} i18nKey="Weight" />
                    </div>
                    <DecimalInput
                      styleClasses="bob"
                      onChange={updateValue}
                      value={inputs.cargoInfoCollapsed[co].weight}
                      path={`cargoInfoCollapsed[${co}].weight`}
                    />
                    <div className="input-unit kg">kg</div>
                  </div>
                </div>
              )

            })
          }
        </div>
      </Collapsible>
    </div>;
  }
  return null;
};

export default withTranslation()(CargoSpecs);
