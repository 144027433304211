import React from "react"
import Label from "../atoms/Label"

const InputForm = (props) => {
  const {
    i18nKey,
    required=false,
    minLength,
    type,
    value,
    dataId,
    setValue,
    autoComplete,
    disabled=false
  } = props

  return (
    <div>
      <Label type='input' i18nKey={i18nKey} />
      <input
        required={required}
        minLength={minLength}
        className="input fill"
        type={type}
        value={value}
        data-id={dataId}
        onChange={(e) => setValue(e.target.value)}
        autoComplete={autoComplete}
        disabled={disabled}
      />
    </div>
  )
}

export default InputForm