import React from 'react'

import H2Title from '../atoms/H2Title'
import Label from '../atoms/Label'

const ExportResultFileSelector = (props) => {
  const { onChangeHandle } = props

  return (
    <div>
      <H2Title
        type='subtitle'
        i18nKey='export_result_file'
      />
      <input
        type='checkbox'
        id='export_excel'
        onChange={(e) => onChangeHandle(e.target.checked)}
        style={{marginTop: '10px'}}
      />
      <Label i18nKey='export_excel' inputFor='export_excel' />
    </div>
  )
}

export default ExportResultFileSelector
