import React from 'react'

import Label from '../atoms/Label'

const CheckboxForm = (props) => {
  const {
    i18nKey,
    disabled=false,
    onChange,
    optionsList,
    defaultCheckedList=null
  } = props
  const isEditMode = !!defaultCheckedList

  const handleOnChange = (e) => {
    const isChecked = e.target.checked
    const targetWarehouseId = e.target.id

    onChange(isChecked, targetWarehouseId)
  }

  const returnIsDefaultChecked = (id) => {
    if (isEditMode) {
      if (!!defaultCheckedList.find(defaultId => id === defaultId)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return (
    <div>
      <Label i18nKey={i18nKey} />
      <div style={{
        overflowY: 'scroll',
        height: '75px'
      }}>
        {optionsList.map((option, i) => {
          const isDefaultChecked = returnIsDefaultChecked(option['id'])
          return (
            <div key={`${i}-${option['id']}`}>
              {isDefaultChecked ? (
                <input
                  type='checkbox'
                  disabled={disabled}
                  id={option['id']}
                  onClick={(e) => handleOnChange(e)}
                  defaultChecked='checked'
                />
              ) : (
                <input
                  type='checkbox'
                  disabled={disabled}
                  id={option['id']}
                  onChange={(e) => handleOnChange(e)}
                />
              )}
              <Label value={option['name']} inputFor={option['id']} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CheckboxForm
