import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { map as lMap } from 'lodash'

import Label from '../atoms/Label'

const SelectInputForm = (props) => {
  const { t } = useTranslation()

  const {
    i18nKey='',
    disabled=false,
    display='block',
    isNewExec=false,
    value,
    onChange,
    options,
  } = props

  return (
    <div>
      <Label i18nKey={i18nKey} />
      {disabled ? (
        <div>
          <Label value={options[0]} />
        </div>
      ) : (
        <select
          className='input-select'
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={{
            display: display,
            height: isNewExec ? '34px' : ''
          }}
        >
          {lMap(options, (text, value) => (
            <option key={value} value={value}>
              <Trans t={t} i18nKey={text} />
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

export default SelectInputForm
