import React, { useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { map as lMap, sortBy as lSort } from 'lodash'
import ResultRow from '../../../../molecules/ResultRow'
import { AuthContext } from '../../../../../app'
import Cookies from 'js-cookie'

import {
  apiGetAsoko2SimulationResults,
  apiGetAllCompanies,
  apiGetCompanyFromId,
  apiGetWarehouseFromCompanyId,
  apiGetWarehouseAssignFromUserId,
} from '../../../../../utils/api'

import ServiceButtonsSet from '../../../../molecules/ServiceButtonsSet'
import BasicInfoPanel from '../../../../organisms/BasicInfoPanel'

const SimulationResHis = ({ t, setExecStatus }) => {
  const { userDetails } = useContext(AuthContext)
  const { company_id, role, id } = userDetails

  const [resultList, setResultList] = useState(null)
  const [companiesList, setCompaniesList] = useState([])
  const [selectedCompanyNo, setSelectedCompanyNo] = useState(0)
  const [warehousesList, setWarehousesList] = useState([])
  const [selectedWarehouseNo, setSelectedWarehouseNo] = useState(0)
  const [initFlg, setInitFlg] = useState(false)
  const [ isChangeFlag, setIsChangeFlag ] = useState(false)

  const fetchResultList = () => {
    const exec_status = Cookies.get('exec_status')
    const kind = Cookies.get('kind')
    if(kind === 's' && exec_status === 'done') {
      Cookies.set('exec_status', '')
      Cookies.set('kind', '')
      setExecStatus('done')
    }
    const cookies_warehouseId = Cookies.get('warehouse_id')
    // console.log('---cookies_warehouseId --> ' + cookies_warehouseId)

    async function fetchData() {
      const {
        data: { data: res },
      } = await apiGetAsoko2SimulationResults(cookies_warehouseId)
      setResultList(res.reverse())
    }
    fetchData()
    setIsChangeFlag(false)
  }

  useEffect(() => {
    isChangeFlag && fetchResultList()
  }, [isChangeFlag])

  const fetchPanelData = async () => {
    const companyIdFromCookie =
      (role === 'admin' && Cookies.get('company_id'))
        ? Cookies.get('company_id')
        : company_id

    const {
      data: { data: details_c },
    } = (role === 'admin')
      ? await apiGetAllCompanies()
      : await apiGetCompanyFromId(companyIdFromCookie)

    const indexOfCompanyNo = details_c.indexOf(
      details_c.find(details => details['id'] === companyIdFromCookie)
    )
    const companyNoFromCookie = !!indexOfCompanyNo
      ? indexOfCompanyNo
      : (role === 'admin' && Cookies.get('company_no'))
        ? Number(Cookies.get('company_no'))
        : 0

    setCompaniesList(details_c)
    setSelectedCompanyNo(companyNoFromCookie)

    const warehouseNoFromCookie =
      Cookies.get('warehouse_no')
        ? Number(Cookies.get('warehouse_no'))
        : 0

    const {
      data: { data: details_w },
    } = await apiGetWarehouseFromCompanyId(companyIdFromCookie)

    const {
      data: { data: details_assigned },
    } = await apiGetWarehouseAssignFromUserId(id)

    const filteredDetails_w =
      (role === 'admin')
        ? details_w
        : details_w.filter(
            warehouse => details_assigned.find(
              assigned => assigned['warehouse_id'] === warehouse['id']
            )
          )

    setWarehousesList(filteredDetails_w)
    setSelectedWarehouseNo(warehouseNoFromCookie)

    Cookies.set('company_no', companyNoFromCookie)
    Cookies.set('company_id', details_c[companyNoFromCookie]['id'])
    Cookies.set('warehouse_no', warehouseNoFromCookie)
    Cookies.set('warehouse_id', filteredDetails_w[warehouseNoFromCookie]['id'])
    setInitFlg(true)
  }

  const initData = async () => {
    await fetchPanelData()
    await fetchResultList()
  }

  useEffect(() => {
    initData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const props = {
    i18nKey: 'simulation_reshis',
    handleSelectId: setIsChangeFlag,
    companiesList: companiesList,
    selectedCompanyNo: selectedCompanyNo,
    setSelectedCompanyNo: setSelectedCompanyNo,
    warehousesList: warehousesList,
    setWarehousesList: setWarehousesList,
    selectedWarehouseNo: selectedWarehouseNo,
    setSelectedWarehouseNo: setSelectedWarehouseNo,
    fetchPanelData: fetchPanelData,
    initFlg: initFlg,
  }

  return (
    <div className='padding2'>
      <BasicInfoPanel props={props} />
      <div>
        <br/>
        <table className='asoko2-table' cellSpacing={0}>
          <thead>
            <tr>
              <th><Trans t={t} i18nKey='executed' /></th>
              <th><Trans t={t} i18nKey='exec_filename' /></th>
              <th><Trans t={t} i18nKey='result' /></th>
              <th><Trans t={t} i18nKey='result_report' /></th>
              <th><Trans t={t} i18nKey='opt_picking_list' /></th>
              <th><Trans t={t} i18nKey='workload_list' /></th>
              <th><Trans t={t} i18nKey='executed_user' /></th>
              </tr>
          </thead>
          <tbody>
            {lMap(lSort(resultList, 'first_name'), (u, index) => (
              <ResultRow
                key={index}
                u={u}
              />
            ))}
          </tbody>
        </table>
      </div>
      <ServiceButtonsSet backTo='/simulationmenu' />
    </div>
  )
}

export default SimulationResHis
