import React from "react"
import { NavLink } from "react-router-dom"

import { ListItemButton } from "@mui/material"

import Icon from "../atoms/Icon"
import Label from "../atoms/Label"

const LeftMenuItem = (props) => {
  const { isChildren=false, to, iconName, i18nKey } = props
  const pl = isChildren ? 4 : 0
  const MenuHeight = 40

  return (
    <NavLink
      className="sidebar-nav-item-link"
      to={to}
      style={{ height: MenuHeight }}
    >
      <ListItemButton sx={{ pl }}>
        <Icon iconName={iconName} />
        <Label type='default' i18nKey={i18nKey} />
      </ListItemButton>
    </NavLink>
  )
}

export default LeftMenuItem