/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

const Carton = (props) => {
  const { nodes, materials } = useGLTF("/new_models/carton.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.pallet_3001.geometry}
        material={materials["pallet_3.001"]}
      />
    </group>
  );
}

useGLTF.preload("/new_models/carton.glb");

export default Carton;
