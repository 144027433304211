import React, {useEffect, useRef, useState} from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import GridLayout from "react-grid-layout";
import InputSection from "./input";
import {
  STORAGE_METHODS
} from "../../utils";
import Visualization from "./viz";
import OutputSection from "./output";
import screenfull from "screenfull";
import {Trans, withTranslation} from "react-i18next";
import {getOutput} from "../../utils/output";
import {apiGetPlanDetails, apiUpdatePlanInputs} from "../../utils/api";
import {DEFAULT_INPUT} from "../../utils/constants";
import SettingsModal from "./input/settings_modal";

const DragHandle = () => (
  <div className="dragHandle">
    <span className="material-icons">
      drag_indicator
    </span>
  </div>
);

const PlanPage = ({ t }) => {
  const { plan_id: planId } = useParams();
  const pageRef = useRef(null);
  const vizRef = useRef(null);
  const [ settingsModalOpen, setSettingsModalOpen ] = useState(false);
  const [pageDimensions, setPageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [layout, setLayout] = useState([
    { i: "input", x: 0, y: 0, w: 4, h: 10 },
    { i: "viz", x: 5, y: 0, w: 8, h: 5 },
    { i: "output", x: 5, y: 5, w: 8, h: 5 }
  ]);
  const [inputs, setInputs] = useState(null);

  const saveInputs = async () => {
    await apiUpdatePlanInputs(planId, inputs);
    setUnsavedChanges(false);
  }

  const setSize = () => {
    const container = pageRef.current;
    if(!!container) {
      setPageDimensions({
        width: container.clientWidth,
        height: container.clientHeight,
      });
    }
  }

  useEffect(() => {
    setSize();
    window.addEventListener("resize", setSize);
    return () => {
      window.removeEventListener("resize", setSize);
    }
  }, [pageRef]);

  useEffect(() => {
    const getPlanDetails = async () => {
      const { data: { data: apiResponse }} = await apiGetPlanDetails(planId);
      setInputs({
        ...DEFAULT_INPUT,
        ...(apiResponse.inputs || {})
      });
    }
    getPlanDetails();
  }, [planId]);

  const updateValue = (e) => {
    const path = _.get(e, "target.dataset.path");

    setUnsavedChanges(true);

    if(!path) {
      setInputs({
        ..._.cloneDeep(inputs),
        ...e,
      });
      return;
    }

    const value = e.target.value;

    // To prevent numbers from being stored as text which creates probems for operations like + in Math calculations.
    // const sanitisedValue = _.isNaN(_.toNumber(value)) ? value : _.toNumber(value);

    const newInputs = _.cloneDeep(inputs);
    // _.set(newInputs, path, sanitisedValue);
    _.set(newInputs, path, value);

    if(path === "cargoType") {
      const storageMethod = _.find(STORAGE_METHODS, s => _.includes(s.cargo_types, value));
      _.set(newInputs, "storageMethod", storageMethod.text);
    }

    setInputs(newInputs);
  }

  const toggleVizFullscreen = () => {
    screenfull.toggle(vizRef.current);
  }

  const newOutput = inputs ? getOutput(inputs) : null;

  return (
    <div id="plan-page" ref={pageRef}>
      {
        settingsModalOpen && (
          <SettingsModal inputs={inputs} updateValue={updateValue} setSettingsModalOpen={setSettingsModalOpen} />
        )
      }
      <div className="plan-settings">
        <button className="btn" onClick={() => setSettingsModalOpen(true)}>
          <span className="material-icons">
            settings
          </span>
        </button>
        {
          unsavedChanges && (
            <div>
              Some of the changes are not saved. <button className="btn primary" onClick={saveInputs}>Save</button> <button className="btn" onClick={e => {
                window.location.reload();
              }}>Cancel</button>
            </div>
          )
        }
      </div>
      {
        inputs ? (
          <GridLayout
            width={pageDimensions.width}
            height={pageDimensions.height}
            rowHeight={pageDimensions.height / 10 - 12}
            cols={12}
            layout={layout}
            onLayoutChange={(layout) => { setLayout(layout)}}
            draggableHandle=".dragHandle"
          >
            <div key="input">
              <div  id="input-section">
                <InputSection inputs={inputs} updateValue={updateValue} />
              </div>
              <DragHandle />
            </div>
            <div key="viz">
              <div ref={vizRef} id="viz-section">
                <h4 className="section-heading" >
                  <Trans t={t} i18nKey="Visualization" />
                </h4>
                <Visualization errors={newOutput.errors} inputs={inputs} newOutput={newOutput} toggleFullscreen={toggleVizFullscreen} />
              </div>
              <DragHandle />
            </div>
            <div key="output">
              <div id="output-section">
                <h4 className="section-heading">
                  <Trans t={t} i18nKey="Dashboard" />
                </h4>
                <OutputSection errors={newOutput.errors} inputs={inputs} newOutput={newOutput} />
              </div>
              <DragHandle />
            </div>
          </GridLayout>
        ) : null
      }
    </div>
  )
};

export default withTranslation()(PlanPage);
