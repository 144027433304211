import React from "react";
import {CARGO_TYPES, ENUM_CARGO_TYPES} from "../../../utils";
import {Trans, withTranslation} from "react-i18next";
import CargoSpecs from "./cargo_specs";
import StorageMethods from "./storage_methods";
import _ from "lodash";

const CargoInfo = ({ inputs, updateValue, t }) => {
  return (
    <div className="input-margin-section">
      {
        CARGO_TYPES.map(c => {
          const canEnterCargoSpecs = _.includes([
            ENUM_CARGO_TYPES.pallet,
            ENUM_CARGO_TYPES.rollbox_pallet
          ], c.text);
          const rowContent = (
            <div className="input-row" key={c.text}>
              <div className={c.options.length > 0 ? "input-row-text" : ""}>
                <label className="checkbox-container">
                  <Trans t={t} i18nKey={c.text} />
                  <input type="checkbox" checked={inputs.cargoType === c.text} onChange={() => {
                    updateValue({
                      target: {
                        value: c.text,
                        dataset: {
                          path: "cargoType"
                        }
                      }
                    })
                  }} />
                  <span className="checkbox-mark"></span>
                </label>
              </div>

              <div className="input-wrapper" />
            </div>
          );

          const updateCargoOption = (optionValue) => {
            const specsValues = inputs.cargoInfoCollapsed[optionValue];
            updateValue({
              cargoSpecs: specsValues,
              [c.optionInputKey]: optionValue,
            });
          }

          if(inputs.cargoType === c.text) {
            let CollapsedContent = null;
            if(c.text !== ENUM_CARGO_TYPES.piece) {
              CollapsedContent = <CargoSpecs canEnterCargoSpecs={canEnterCargoSpecs} cargo={c} inputs={inputs} updateValue={updateValue} />;
            }
            return (
              <div key={c.text} className="input-collapse">
                <div className="input-collapse-head">
                  {rowContent}
                </div>
                <div className="input-row cargo-info-collapsed-content">
                  <div className="input-row-text">
                    {
                      c.options.length > 0 && (
                        <select className="input-field" value={inputs[c.optionInputKey]} onChange={(e) => updateCargoOption(e.target.value)}>
                          {
                            c.options.map(o => (
                              <option key={o} value={o}>
                                <Trans t={t} i18nKey={o} />
                              </option>
                            ))
                          }
                        </select>
                      )
                    }
                  </div>
                </div>
                {CollapsedContent}
                {
                  c.text !== ENUM_CARGO_TYPES.rollbox_pallet && (
                    <div className="cargo-info-collapsed-content">
                      <h4 className="section-heading">
                        <Trans t={t} i18nKey="Storage method" />
                      </h4>
                      <StorageMethods canEnterCargoSpecs={canEnterCargoSpecs} inputs={inputs} updateValue={updateValue} />
                    </div>
                  )
                }
              </div>
            )
          }
          return <div key={c.text}>
            {rowContent}
          </div>;
        })
      }
    </div>
  );
}

export default withTranslation()(CargoInfo);