import React  from "react";
import { useGLTF } from "@react-three/drei";

export function PalletRack1P2S(props) {
  const { nodes, materials } = useGLTF("/new_models/pr_1x_2y.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Cube004.geometry}
        material={materials["Material.009"]}
      />
    </group>
  );
}

useGLTF.preload("/new_models/pr_1x_2y.glb");

export default PalletRack1P2S;
