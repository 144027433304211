import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../../app'
import Cookies from 'js-cookie'
import { Trans, useTranslation } from 'react-i18next'

import { apiGetWarehouseFromCompanyId } from '../../utils/api'

import H2Title from '../atoms/H2Title'
import SelectInputForm from '../molecules/SelectInputForm'

const BasicInfoPanel = (props) => {
  const { t } = useTranslation()
  const lang = Cookies.get('lang') || 'ja'
  const {
    i18nKey,
    handleSelectId,
    companiesList,
    selectedCompanyNo,
    setSelectedCompanyNo,
    warehousesList,
    setWarehousesList,
    selectedWarehouseNo,
    setSelectedWarehouseNo,
    fetchPanelData,
    initFlg,
  } = props.props
  const { userDetails } = useContext(AuthContext)
  const { first_name, last_name, email, role } = userDetails
  const userFullName =
    lang === 'ja'
    ? (`${last_name} ${first_name}`)
    : (`${first_name} ${last_name}`)

  const handleChangeCompany = async () => {
    const companyId = companiesList[selectedCompanyNo]
      ? companiesList[selectedCompanyNo]['id']
      : ''
    Cookies.set('company_no', selectedCompanyNo)
    Cookies.set('company_id', companyId)

    const {
      data: { data: details },
    } = await apiGetWarehouseFromCompanyId(companyId)
    setWarehousesList(details)
    setSelectedWarehouseNo(0)
    Cookies.set('warehouse_no', 0)
    Cookies.set('warehouse_id', details[0]['id'])
    handleSelectId(true)
  }

  const handleChangeWarehouse = async () => {
    const WarehouseId = warehousesList[selectedWarehouseNo]
      ? warehousesList[selectedWarehouseNo]['id']
      : ''
    Cookies.set('warehouse_no', selectedWarehouseNo)
    Cookies.set('warehouse_id', WarehouseId)
    handleSelectId(true)
  }

  useEffect(() => {
    if(initFlg) {
      handleChangeCompany()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyNo])

  useEffect(() => {
    if(initFlg) {
      handleChangeWarehouse()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWarehouseNo])

  useEffect(() => {
    if(!initFlg) {
      fetchPanelData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const margin = '5px 0 5px'

  return (
    <div>
      <H2Title type='home-menu' i18nKey={i18nKey} />
      <div className='grid-user-container'>
        <div className='grid-item' style={{margin: margin}}>
          <Trans t={t} i18nKey='company_name' />
        </div>
        <div className='grid-item' style={{margin: margin}}>：</div>
        <div className='grid-item' style={{margin: margin}}>
          <SelectInputForm
            disabled={role !== 'admin'}
            value={selectedCompanyNo}
            onChange={setSelectedCompanyNo}
            options={companiesList.map(value => value.name)}
          />
        </div>
        <div className='grid-item' style={{margin: margin}}>
          <Trans t={t} i18nKey='role' />
        </div>
        <div className='grid-item' style={{margin: margin}}>：</div>
        <div className='grid-item' style={{margin: margin}}>
          <Trans t={t} i18nKey={`role_${role}`} />
        </div>
        <div className='grid-item' style={{margin: margin}}>
          <Trans t={t} i18nKey='warehouse_name' />
        </div>
        <div className='grid-item' style={{margin: margin}}>：</div>
        <div className='grid-item' style={{margin: margin}}>
          <SelectInputForm
            value={selectedWarehouseNo}
            onChange={setSelectedWarehouseNo}
            options={warehousesList.map(value => value.name)}
          />
        </div>
        <div className='grid-item' style={{margin: margin}}>
          <Trans t={t} i18nKey='email' />
        </div>
        <div className='grid-item' style={{margin: margin}}>：</div>
        <div className='grid-item' style={{margin: margin}}>{email}</div>
        <div className='grid-item' style={{margin: margin}}>
          <Trans t={t} i18nKey='full_name' />
        </div>
        <div className='grid-item' style={{margin: margin}}>：</div>
        <div className='grid-item' style={{margin: margin}}>{userFullName}</div>
      </div>
    </div>
  )
}

export default BasicInfoPanel
